import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginView from "@/features/authentication/LoginView.vue";
import UnauthorizedView from "@/features/authorization/UnauthorizedView.vue";
import NotFound from "@/features/errors/NotFound.vue";
import ServerError from "@/features/errors/ServerError.vue";
import Forbidden from "@/features/errors/Forbidden.vue";
import PreparationView from "@/features/preparation/departures/PreparationView.vue";
import RegularDepartures from "@/features/preparation/departures/RegularDepartures.vue";
import ReserveDepartures from "@/features/preparation/departures/reserves/ReserveDepartures.vue";
import WorkSchedule from "@/features/documents/WorkSchedule.vue";
import IncidentsView from "@/features/incidents/incidentsList/IncidentsView.vue";
import IncidentView from "@/features/incidents/incidentView/IncidentView.vue";
import VehiclesWrapper from "@/features/vehicles/VehiclesWrapper.vue";
import VehiclesView from "@/features/vehicles/vehicles/VehiclesView.vue";
import VehicleRoutesView from "@/features/vehicles/vehicleRoutes/VehicleRoutesView.vue";
import VehicleRedirectionView from "@/features/vehicles/vehicles/VehicleRedirectionView.vue";
import ReroutesView from "@/features/preparation/reroutes/ReroutesView.vue";
import JobDetails from "@/features/jobs/JobDetails.vue";
import JobGeneralInfo from "@/features/jobs/JobGeneralInfo.vue";
import DelaysAndNotes from "@/features/documents/public/DelaysAndNotes.vue";
import PublicDocumentsView from "@/features/documents/public/PublicDocumentsView.vue";
import DocumentsView from "@/features/documents/dispatcher/DocumentsView.vue";
import WorkOrder from "@/features/documents/WorkOrder.vue";
import PublicWorkOrder from "@/features/documents/public/PublicWorkOrder.vue";
import PublicWorkSchedule from "@/features/documents/public/PublicWorkSchedule.vue";
import LineSchedule from "@/features/documents/LineSchedule.vue";
import PublicLineSchedule from "@/features/documents/public/PublicLineSchedule.vue";
import AdminView from "@/features/admin/AdminWrapper.vue";
import UsersView from "@/features/admin/users/UsersView.vue";
import ReservesWrapper from "@/features/reserves/ReservesWrapper.vue";
import ReserveJobsView from "@/features/reserves/jobs/ReserveJobsView.vue";
import ReserveDriversView from "@/features/reserves/drivers/ReserveDriversView.vue";
import ReserveVehiclesView from "@/features/reserves/vehicles/ReserveVehiclesView.vue";
import JobOrdersView from "@/features/jobOrders/JobOrdersView.vue";
import JobsMonitoringView from "@/features/jobsMonitoring/JobsMonitoringView.vue";
import InProgressJobMonitoring from "@/features/jobsMonitoring/InProgressJobs/InProgressJobMonitoring.vue";
import EndedJobsMonitoring from "@/features/jobsMonitoring/EndedJobs/EndedJobsMonitoring.vue";
import DeparturesMonitoringTable from "@/features/jobsMonitoring/DeparturesMonitoringTable.vue";
import StyleGuideView from "@/features/styleGuide/StyleGuideView.vue";
import DeparturesTableView from "@/features/departuresTable/DeparturesTableView.vue";
import ReservePendingDriversView from "@/features/reserves/pending-drivers/ReservePendingDriversView.vue";
import LineSchedulePrinting from "@/features/preparation/lineSchedules/printing/LineSchedulePrinting.vue";
import ParkingLotsView from "@/features/admin/parkingLots/ParkingLotsView.vue";
import ClassificatorsView from "@/features/admin/classificators/ClassificatorsView.vue";
import ServiceAccountsView from "@/features/admin/users/ServiceAccountsView.vue";
import NotificationGroupsView from "@/features/admin/notificationGroups/NotificationGroupsView.vue";
import ExternalServicesMonitoringView from "@/features/externalServicesMonitoring/ExternalServicesMonitoringView.vue";
import Interruptions from "@/features/externalServicesMonitoring/interruptions/Interruptions.vue";
import UpdateTimes from "@/features/externalServicesMonitoring/updateTimes/UpdateTimes.vue";
import AccountDisplayManagementView from "@/features/admin/users/AccountDisplayManagementView.vue";
import PhoneNumberContactsView from "@/features/admin/phoneNumberContacts/PhoneNumberContactsView.vue";
import SystemParametersView from "@/features/admin/systemParameters/SystemParametersView.vue";
import MetricsWrapper from "@/features/metrics/MetricsWrapper.vue";
import MetricsDashboardView from "@/features/metrics/dashboard/MetricsDashboardView.vue";
import IncidentMetricsView from "@/features/metrics/incidents/IncidentMetricsView.vue";
import userInfo from "@/components/UserInfo";
import JobDelays from "@/features/jobDelays/JobDelays.vue";
import DelayMetricsView from "@/features/metrics/delays/DelayMetricsView.vue";
import ReserveMetricsView from "@/features/metrics/reserves/ReserveMetricsView.vue";
import HealthInspectionMetricsView from "@/features/metrics/healthInspections/HealthInspectionMetricsView.vue";
import UnservedTripMetricsView from "@/features/metrics/unservedTrips/UnservedTripMetricsView.vue";
import PlannedAndServedKmMetricsView from "@/features/metrics/plannedAndServedKm/PlannedAndServedKmMetricsView.vue";
import PendingJobsView from "@/features/reserves/pending-jobs/PendingJobsView.vue";
import { api } from "@/api-client";
import ViruTerminalManagementView from "@/features/terminals/viru/management/ViruTerminalManagementView.vue";
import ViruTerminalView from "@/features/terminals/viru/ViruTerminalView.vue";
import ViruTerminalDepartureView from "@/features/terminals/viru/departure/ViruTerminalDepartureView.vue";
import ViruTerminalArrivalView from "@/features/terminals/viru/arrival/ViruTerminalArrivalView.vue";
import ParkingLotsStatusView from "@/features/preparation/parkingLots/ParkingLotsStatusView.vue";
import InsuranceView from "@/features/admin/insurance/InsuranceView.vue";
import PublicReroutes from "@/features/documents/public/PublicReroutes.vue";
import DispatcherReroutes from "@/features/documents/dispatcher/DispatcherReroutes.vue";

export enum RouteName {
    Login = "login",
    NotFound = "not-found",
    ServerError = "server-error",
    Forbidden = "forbidden",
    Unauthorized = "unauthorized",
    Preparation = "preparation",
    RegularDepartures = "regular-departures",
    ReserveDepartures = "reserve-departures",
    Reroutes = "reroutes",
    ParkingLotsStatus = "parking-lots-status",
    JobDetails = "job-details",
    JobGeneralInfo = "job-general-info",
    DispatcherDocuments = "dispatcher-documents",
    WorkOrder = "work-order",
    WorkSchedule = "work-schedule",
    LineSchedule = "line-schedule",
    DispatcherReroutes = "dispatcher-reroutes",
    Vehicles = "vehicles",
    VehiclesTable = "vehicles-table",
    VehicleRoutesTable = "vehicle-routes-table",
    Reserves = "reserves",
    ReserveJobs = "reserve-jobs",
    PendingDrivers = "pending-drivers",
    PendingJobs = "pending-jobs",
    ReserveDrivers = "reserve-drivers",
    ReserveVehicles = "reserve-vehicles",
    JobOrders = "job-orders",
    Metrics = "metrics",
    MetricsDashboard = "metrics-dashboard",
    MetricsIncidents = "metrics-incidents",
    MetricsUnservedTrips = "metrics-unserved-trips",
    MetricsDelays = "metrics-delays",
    MetricsReserves = "metrics-reserves",
    MetricsHealthInspections = "metrics-health-inspections",
    MetricsPlannedAndServedKm = "metrics-planned-and-served-km",
    VehicleRedirect = "vehicle-redirect",
    PublicDocuments = "public-documents",
    PublicWorkOrder = "public-work-order",
    PublicWorkSchedule = "public-work-schedule",
    PublicLineSchedule = "public-line-schedule",
    PublicReroutes = "public-reroutes",
    JobNotesAndDelays = "job-notes-and-delays",
    Incidents = "incidents",
    Incident = "incident",
    Admin = "admin",
    Users = "users",
    InsuranceContracts = "insuranceContracts",
    ServiceAccounts = "service-accounts",
    ParkingLots = "parking-lots",
    ServiceTableManagement = "service-table-management",
    SystemParameters = "system-parameters",
    Classificators = "classificators",
    NotificationGroups = "notification-groups",
    PhoneNumberContacts = "phone-number-contacts",
    JobsMonitoring = "monitoring",
    InProgressJobs = "in-progress-jobs",
    EndedJobs = "ended-jobs",
    DeparturesTable = "departures-table",
    StyleGuide = "style-guide",
    DeparturesTableView = "departures-table-view",
    LineSchedules = "line-schedules",
    JobDelays = "job-delays",
    ExternalServicesMonitoringView = "external-services-monitoring-view",
    UpdateTimes = "update-times",
    Interruptions = "interruptions",
    ViruTerminal = "viru-terminal",
    ViruTerminalManagement = "viru-terminal-management",
    ViruTerminalDeparture = "viru-terminal-departure",
    ViruTerminalArrival = "viru-terminal-arrival",
}

const routes: Array<RouteRecordRaw> = [
    {
        path: "/sisene",
        alias: "/login",
        name: RouteName.Login,
        component: LoginView,
        meta: {
            hideMainNavbar: true,
        },
    },
    {
        path: "/ei-leitud",
        name: RouteName.NotFound,
        component: NotFound,
    },
    {
        path: "/:pathMatch(.*)*",
        name: RouteName.NotFound,
        component: NotFound,
    },
    {
        path: "/viga",
        name: RouteName.ServerError, // Used in ServiceAccount redirection check in beforeEach
        component: ServerError,
    },
    {
        path: "/oigused-puuduvad",
        name: RouteName.Forbidden,
        component: Forbidden,
    },
    {
        path: "/unauthorized",
        name: RouteName.Unauthorized,
        component: UnauthorizedView,
    },
    {
        path: "/",
        redirect: (to) => {
            if (userInfo.defaultView === "/") {
                return { name: RouteName.Preparation };
            } else {
                const [, paramString] = userInfo.defaultView.split("?");
                const query = groupParamsByKey(new URLSearchParams(paramString));
                return { path: userInfo.defaultView, query: query };
            }
        },
    },
    {
        path: "/ettevalmistamine",
        name: RouteName.Preparation,
        component: PreparationView,
        redirect: { name: RouteName.RegularDepartures },
        children: [
            {
                path: "",
                name: RouteName.RegularDepartures,
                component: RegularDepartures,
            },
            {
                path: "reservid",
                name: RouteName.ReserveDepartures,
                component: ReserveDepartures,
            },
            {
                path: "/umbersoidud",
                name: RouteName.Reroutes,
                component: ReroutesView,
            },
            {
                path: "paaslad",
                name: RouteName.ParkingLotsStatus,
                component: ParkingLotsStatusView,
            },
        ],
    },
    {
        path: "/too/:jobId/:driverId",
        name: RouteName.JobDetails,
        component: JobDetails,
        children: [
            {
                path: "uldandmed",
                name: RouteName.JobGeneralInfo,
                component: JobGeneralInfo,
            },
            {
                path: "dokumendid",
                name: RouteName.DispatcherDocuments,
                component: DocumentsView,
                children: [
                    {
                        path: "tookorraldus",
                        name: RouteName.WorkOrder,
                        component: WorkOrder,
                    },
                    {
                        path: "toograafik",
                        name: RouteName.WorkSchedule,
                        component: WorkSchedule,
                    },
                    {
                        path: "liinigraafik",
                        name: RouteName.LineSchedule,
                        component: LineSchedule,
                    },
                    {
                        path: "umbersoidud",
                        name: RouteName.DispatcherReroutes,
                        component: DispatcherReroutes,
                    },
                ],
            },
        ],
    },
    {
        path: "/soidukid",
        name: RouteName.Vehicles,
        component: VehiclesWrapper,
        children: [
            {
                // empty path is used for the 'vehicles' view
                path: "",
                name: RouteName.VehiclesTable,
                component: VehiclesView,
            },
            {
                path: "trass",
                name: RouteName.VehicleRoutesTable,
                component: VehicleRoutesView,
            },
        ],
    },
    {
        path: "/reservid",
        name: RouteName.Reserves,
        component: ReservesWrapper,
        redirect: { name: RouteName.ReserveJobs },
        children: [
            {
                path: "tool",
                name: RouteName.ReserveJobs,
                component: ReserveJobsView,
            },
            {
                path: "ootel-juhid",
                name: RouteName.PendingDrivers,
                component: ReservePendingDriversView,
            },
            {
                path: "ootel-tood",
                name: RouteName.PendingJobs,
                component: PendingJobsView,
            },
            {
                path: "soidukijuhid",
                name: RouteName.ReserveDrivers,
                component: ReserveDriversView,
            },
            {
                path: "soidukid",
                name: RouteName.ReserveVehicles,
                component: ReserveVehiclesView,
            },
        ],
    },
    {
        path: "/soidukorraldused",
        name: RouteName.JobOrders,
        component: JobOrdersView,
    },
    {
        path: "/moodikud",
        name: RouteName.Metrics,
        component: MetricsWrapper,
        redirect: { name: RouteName.MetricsDashboard },
        children: [
            {
                path: "ulevaade",
                name: RouteName.MetricsDashboard,
                component: MetricsDashboardView,
            },
            {
                path: "juhtumid",
                name: RouteName.MetricsIncidents,
                component: IncidentMetricsView,
            },
            {
                path: "tegemata-veootsad",
                name: RouteName.MetricsUnservedTrips,
                component: UnservedTripMetricsView,
            },
            {
                path: "joudmine-ja-valjumine",
                name: RouteName.MetricsDelays,
                component: DelayMetricsView,
            },
            {
                path: "reservid",
                name: RouteName.MetricsReserves,
                component: ReserveMetricsView,
            },
            {
                path: "tervisekontrollid",
                name: RouteName.MetricsHealthInspections,
                component: HealthInspectionMetricsView,
            },
            {
                path: "planeeritud-kilomeetrid",
                name: RouteName.MetricsPlannedAndServedKm,
                component: PlannedAndServedKmMetricsView,
            },
        ],
    },
    {
        // This route is also defined in appsettings.json - PublicDocumentsConfiguration.
        // If this URL changes, changes must be carried to appsettings.json as well.
        path: "/avalikud-dokumendid/redirect/:vehicleId",
        name: RouteName.VehicleRedirect,
        component: VehicleRedirectionView,
    },
    {
        path: "/avalikud-dokumendid",
        name: RouteName.PublicDocuments,
        component: PublicDocumentsView,
        meta: {
            hideMainNavbar: true,
        },
        children: [
            {
                path: ":jobId/tookorraldus/:driverId",
                name: RouteName.PublicWorkOrder,
                component: PublicWorkOrder,
            },
            {
                path: ":jobId/toograafik/:driverId",
                name: RouteName.PublicWorkSchedule,
                component: PublicWorkSchedule,
            },
            {
                path: ":jobId/liinigraafik/:driverId",
                name: RouteName.PublicLineSchedule,
                component: PublicLineSchedule,
            },
            {
                path: ":jobId/umbersoidud",
                name: RouteName.PublicReroutes,
                component: PublicReroutes,
            },
            {
                path: ":jobId/teated/:driverId",
                name: RouteName.JobNotesAndDelays,
                component: DelaysAndNotes,
            },
        ],
    },
    {
        path: "/juhtumid/",
        name: RouteName.Incidents,
        component: IncidentsView,
    },
    {
        path: "/juhtumid/:id?",
        name: RouteName.Incident,
        component: IncidentView,
    },
    {
        path: "/peakasutaja/",
        name: RouteName.Admin,
        component: AdminView,
        redirect: { name: RouteName.Users },
        children: [
            {
                path: "kasutajad",
                name: RouteName.Users,
                component: UsersView,
            },
            {
                path: "kindlustuslepingud",
                name: RouteName.InsuranceContracts,
                component: InsuranceView,
            },
            {
                path: "teenusekontod",
                name: RouteName.ServiceAccounts,
                component: ServiceAccountsView,
            },
            {
                path: "paaslad",
                name: RouteName.ParkingLots,
                component: ParkingLotsView,
            },
            {
                path: "tabloode-haldus",
                name: RouteName.ServiceTableManagement,
                component: AccountDisplayManagementView,
            },
            {
                path: "susteemi-parameetrid",
                name: RouteName.SystemParameters,
                component: SystemParametersView,
            },
            {
                path: "klassifikaatorid",
                name: RouteName.Classificators,
                component: ClassificatorsView,
            },
            {
                path: "teavituste-haldus",
                name: RouteName.NotificationGroups,
                component: NotificationGroupsView,
            },
            {
                path: "kontaktide-haldus",
                name: RouteName.PhoneNumberContacts,
                component: PhoneNumberContactsView,
            },
        ],
    },
    {
        path: "/seire",
        name: RouteName.JobsMonitoring,
        component: JobsMonitoringView,
        redirect: { name: RouteName.InProgressJobs },
        children: [
            {
                path: "kestvad-tood",
                name: RouteName.InProgressJobs,
                component: InProgressJobMonitoring,
            },
            {
                path: "loppenud-tood",
                name: RouteName.EndedJobs,
                component: EndedJobsMonitoring,
            },
            {
                path: "valjumiste-tabloo",
                name: RouteName.DeparturesTable,
                component: DeparturesMonitoringTable,
            },
        ],
    },
    {
        path: "/stiiliraamat/",
        name: RouteName.StyleGuide,
        component: StyleGuideView,
    },
    {
        path: "/valjumiste-tabloo",
        name: RouteName.DeparturesTableView,
        component: DeparturesTableView,
    },
    {
        path: "/liinigraafikud",
        name: RouteName.LineSchedules,
        component: LineSchedulePrinting,
        meta: {
            hideMainNavbar: true,
        },
    },
    {
        path: "/teated",
        name: RouteName.JobDelays,
        component: JobDelays,
    },
    {
        path: "/monitooring",
        name: RouteName.ExternalServicesMonitoringView,
        component: ExternalServicesMonitoringView,
        redirect: { name: RouteName.UpdateTimes },
        children: [
            {
                path: "uuendused",
                name: RouteName.UpdateTimes,
                component: UpdateTimes,
            },
            {
                path: "katkestused",
                name: RouteName.Interruptions,
                component: Interruptions,
            },
        ],
    },
    {
        path: "/terminalid/viru",
        name: RouteName.ViruTerminal,
        component: ViruTerminalView,
        redirect: { name: RouteName.ViruTerminalManagement },
        children: [
            {
                path: "haldus",
                name: RouteName.ViruTerminalManagement,
                component: ViruTerminalManagementView,
            },
            {
                path: "valjuvad",
                name: RouteName.ViruTerminalDeparture,
                component: ViruTerminalDepartureView,
            },
            {
                path: "saabuvad",
                name: RouteName.ViruTerminalArrival,
                component: ViruTerminalArrivalView,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    removeModalElements();

    if (userInfo.roleType == api.RoleType.ServiceAccount && to.name === RouteName.ServerError) {
        setTimeout(() => {
            window.location.href = userInfo.defaultView;
        }, 10000); // 10 seconds
    }
    next();
});

const removeModalElements = () => {
    const backdrop = document.querySelector(".modal-backdrop");
    document.body.classList.remove("modal-open");
    document.body.style.paddingRight = "unset";
    document.body.style.overflow = "unset";

    if (backdrop) {
        backdrop.remove();
    }
};

const groupParamsByKey = (params: URLSearchParams) =>
    [...params.entries()].reduce((acc: any, tuple) => {
        // getting the key and value from each tuple
        const [key, val] = tuple;
        if (Object.prototype.hasOwnProperty.call(acc, key)) {
            // if the current key is already an array, we'll add the value to it
            if (Array.isArray(acc[key])) {
                acc[key] = [...acc[key], val];
            } else {
                // if it's not an array, but contains a value, we'll convert it into an array
                // and add the current value to it
                acc[key] = [acc[key], val];
            }
        } else {
            // plain assignment if no special case is present
            acc[key] = val;
        }

        return acc;
    }, {});

export default router;
