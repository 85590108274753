export class RouteMenuItem {
    public name: string;
    public text: string;
    public params: any;
    public notification?: RouteNotification;

    constructor(name: string, text: string, params?: object, hasNotification?: boolean) {
        const notification = hasNotification ? ({ kind: "icon", iconName: "warning", color: "danger" } as const) : undefined;

        this.name = name;
        this.text = text;
        this.params = params;
        this.notification = notification;
    }
}

type IconRouteNotification = { kind: "icon"; iconName: string; color: string };

export type RouteNotification = IconRouteNotification;
