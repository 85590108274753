
import { defineComponent } from "vue";
import NavTop from "@/components/shared/navTop/NavTop.vue";

export default defineComponent({
    name: "App",
    components: {
        NavTop,
    },
});
