import OperationModalTabs, { OperationModalTab } from "@/features/incidents/incidentView/shared/OperationModalTabs";
import { api } from "@/api-client";
import { nameof } from "@/helpers/Utils";

export class ReplacementVehicleAssignmentModel {
    driverAndVehicleId?: number;
    newVehicle?: api.VehicleViewModel;
    newVehicleDepartureLocation?: api.DepartureOperationLocationInputModel | api.ManuallyAddedOperationLocationInputModel | null;
    interruptionLocation?: api.DepartureOperationLocationInputModel | api.ManuallyAddedOperationLocationInputModel | null;
    redirections: api.IncidentOperationRedirectionInputModel[] = [];
    allowOverlappingVehicles = false;

    static from(operation: api.ReplacementVehicleAssignmentOperationViewModel): ReplacementVehicleAssignmentModel {
        return {
            newVehicle: operation.newVehicle,
            newVehicleDepartureLocation:
                operation.startLocation?.toInputModel() ?? (operation.job.runId != null ? new api.DepartureOperationLocationInputModel() : new api.ManuallyAddedOperationLocationInputModel()),
            interruptionLocation: operation.interruptionLocation?.toInputModel(),
            redirections: operation.redirections.map((r) => r.toRedirectionInputModel()),
            allowOverlappingVehicles: false,
        };
    }
}

export default class ReplacementVehicleAssignmentTabs extends OperationModalTabs {
    static JOB_INDEX = 0;
    get JOB(): OperationModalTab {
        return this.getTab(ReplacementVehicleAssignmentTabs.JOB_INDEX);
    }

    static NEW_VEHICLE_INDEX = 1;
    get NEW_VEHICLE(): OperationModalTab {
        return this.getTab(ReplacementVehicleAssignmentTabs.NEW_VEHICLE_INDEX);
    }

    static NEW_VEHICLE_DEPARTURE_LOCATION_INDEX = 2;
    get NEW_VEHICLE_DEPARTURE_LOCATION(): OperationModalTab {
        return this.getTab(ReplacementVehicleAssignmentTabs.NEW_VEHICLE_DEPARTURE_LOCATION_INDEX);
    }

    static INTERRUPTION_POINT_INDEX = 3;
    get INTERRUPTION_POINT(): OperationModalTab {
        return this.getTab(ReplacementVehicleAssignmentTabs.INTERRUPTION_POINT_INDEX);
    }

    static REDIRECTIONS_INDEX = 4;
    get REDIRECTIONS(): OperationModalTab {
        return this.getTab(ReplacementVehicleAssignmentTabs.REDIRECTIONS_INDEX);
    }

    static OVERVIEW_INDEX = 5;
    get OVERVIEW(): OperationModalTab {
        return this.getTab(ReplacementVehicleAssignmentTabs.OVERVIEW_INDEX);
    }

    constructor(tabIndexes: number[], currentTabIndex: number) {
        const jobTab = new OperationModalTab(ReplacementVehicleAssignmentTabs.JOB_INDEX, (_: number, model: ReplacementVehicleAssignmentModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.driverAndVehicleId == null) {
                validationErrors.appendMessage(
                    "Töö ja juht on kohustuslikud",
                    nameof<api.CreateReplacementVehicleAssignmentInputModel>((x) => x.jobId)
                );
            }

            return !validationErrors.hasMessages();
        });

        const newVehicleTab = new OperationModalTab(ReplacementVehicleAssignmentTabs.NEW_VEHICLE_INDEX, (_: number, model: ReplacementVehicleAssignmentModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.newVehicle?.id == null) {
                validationErrors.appendMessage(
                    "Uus sõiduk on kohustuslik",
                    nameof<api.CreateReplacementVehicleAssignmentInputModel>((x) => x.newVehicleId)
                );
            }

            return !validationErrors.hasMessages();
        });

        const newVehicleDepartureLocationTab = new OperationModalTab(
            ReplacementVehicleAssignmentTabs.NEW_VEHICLE_DEPARTURE_LOCATION_INDEX,
            (_: number, model: ReplacementVehicleAssignmentModel, validationErrors: api.ResultError) => {
                validationErrors.clear();

                if (model.newVehicleDepartureLocation == null) {
                    validationErrors.appendMessage(
                        "Uue sõiduki alguspunkt on kohustuslik.",
                        nameof<api.CreateReplacementVehicleAssignmentInputModel>((x) => x.newVehicleDepartureLocation)
                    );
                }

                model.newVehicleDepartureLocation?.validate(validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const interruptionLocationTab = new OperationModalTab(
            ReplacementVehicleAssignmentTabs.INTERRUPTION_POINT_INDEX,
            (_: number, model: ReplacementVehicleAssignmentModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                model.interruptionLocation?.validate(validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const redirectionsTab = new OperationModalTab(
            ReplacementVehicleAssignmentTabs.REDIRECTIONS_INDEX,
            async (incidentId: number, model: ReplacementVehicleAssignmentModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                await api.IncidentOperationRedirectionInputModel.validate(incidentId, model.redirections, validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const overviewTab = new OperationModalTab(ReplacementVehicleAssignmentTabs.OVERVIEW_INDEX, () => true);

        super(
            [jobTab, newVehicleTab, newVehicleDepartureLocationTab, interruptionLocationTab, redirectionsTab, overviewTab].filter((x) => tabIndexes.some((i) => i == x.index)),
            currentTabIndex
        );
    }
}
