import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

// Vehicles on low battery message

export class VehiclesOnLowBatteryHubMessage extends HubMessage<VehiclesOnLowBatteryHubMessage> {
    showRegularDeparturesNotification!: boolean;
    showReserveDeparturesNotification!: boolean;
    showInProgressJobsNotification!: boolean;
    public override overrideFromJSON(data: any): VehiclesOnLowBatteryHubMessage {
        const result = new VehiclesOnLowBatteryHubMessage();
        result.showRegularDeparturesNotification = data["showRegularDeparturesNotification"];
        result.showReserveDeparturesNotification = data["showReserveDeparturesNotification"];
        result.showInProgressJobsNotification = data["showInProgressJobsNotification"];
        return result;
    }
}

export type OnVehiclesOnLowBatteryHubMessage = (message: VehiclesOnLowBatteryHubMessage) => void;

// Publicly inaccessible vehicles message

export class PubliclyInaccessibleVehiclesHubMessage extends HubMessage<PubliclyInaccessibleVehiclesHubMessage> {
    hasPubliclyInaccessibleVehicles!: boolean;

    public override overrideFromJSON(data: any): PubliclyInaccessibleVehiclesHubMessage {
        const result = new PubliclyInaccessibleVehiclesHubMessage();
        result.hasPubliclyInaccessibleVehicles = data["hasPubliclyInaccessibleVehicles"];
        return result;
    }
}
export type OnPubliclyInaccessibleVehiclesHubMessage = (message: PubliclyInaccessibleVehiclesHubMessage) => void;

// Expired updates message

export class ExpiredUpdateTimesHubMessage extends HubMessage<ExpiredUpdateTimesHubMessage> {
    updateNames!: api.ExternalServiceOperationName[];

    public override overrideFromJSON(data: any): ExpiredUpdateTimesHubMessage {
        const result = new ExpiredUpdateTimesHubMessage();
        result.updateNames = data["updateNames"];
        return result;
    }
}

export type OnExpiredUpdateTimesHubMessage = (message: ExpiredUpdateTimesHubMessage) => void;

// External service interruptions message
export class ExternalServiceInterruptionsHubMessage extends HubMessage<ExternalServiceInterruptionsHubMessage> {
    showNotification!: boolean;

    public override overrideFromJSON(data: any): ExternalServiceInterruptionsHubMessage {
        const result = new ExternalServiceInterruptionsHubMessage();
        result.showNotification = data["showNotification"];
        return result;
    }
}

export type OnExternalServiceInterruptionsHubMessage = (message: ExternalServiceInterruptionsHubMessage) => void;

// Expiring reroutes message
export class NotifyOfExpiringReroutesHubMessage extends HubMessage<NotifyOfExpiringReroutesHubMessage> {
    hasExpiringReroutes = false;

    public override overrideFromJSON(data: any): NotifyOfExpiringReroutesHubMessage {
        const result = new NotifyOfExpiringReroutesHubMessage();
        result.hasExpiringReroutes = data["hasExpiringReroutes"];
        return result;
    }
}

export type OnExpiringReroutesHubMessage = (message: NotifyOfExpiringReroutesHubMessage) => void;

// InactiveAlcometers message
export class InactiveAlcometersHubMessage extends HubMessage<InactiveAlcometersHubMessage> {
    hasInactiveAlcometers!: boolean;

    public override overrideFromJSON(data: any): InactiveAlcometersHubMessage {
        const result = new InactiveAlcometersHubMessage();
        result.hasInactiveAlcometers = data["hasInactiveAlcometers"];
        return result;
    }
}
export type OnInactiveAlcometersHubMessage = (message: InactiveAlcometersHubMessage) => void;

export class GlobalNotificationsHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("global-notifications-hub");
    }

    public async open(
        onVehiclesOnLowBatteryHubMessage: OnVehiclesOnLowBatteryHubMessage,
        onPubliclyInaccessibleVehiclesHubMessage: OnPubliclyInaccessibleVehiclesHubMessage,
        onExpiredUpdateTimesHubMessage: OnExpiredUpdateTimesHubMessage,
        onExternalServiceInterruptionsHubMessage: OnExternalServiceInterruptionsHubMessage,
        onExpiringReroutesHubMessage: OnExpiringReroutesHubMessage,
        onInactiveAlcometersHubMessage: OnInactiveAlcometersHubMessage
    ) {
        await super.onMessage(VehiclesOnLowBatteryHubMessage, "NotifyOfVehiclesOnLowBatteries", onVehiclesOnLowBatteryHubMessage);
        await super.onMessage(PubliclyInaccessibleVehiclesHubMessage, "NotifyOfPubliclyInaccessibleVehicles", onPubliclyInaccessibleVehiclesHubMessage);
        await super.onMessage(ExpiredUpdateTimesHubMessage, "NotifyOfExpiredUpdates", onExpiredUpdateTimesHubMessage);
        await super.onMessage(ExternalServiceInterruptionsHubMessage, "NotifyOfExternalServiceInterruptions", onExternalServiceInterruptionsHubMessage);
        await super.onMessage(NotifyOfExpiringReroutesHubMessage, "NotifyOfExpiringReroutes", onExpiringReroutesHubMessage);
        await super.onMessage(InactiveAlcometersHubMessage, "NotifyOfInactiveAlcometers", onInactiveAlcometersHubMessage);

        await super.start();

        return this;
    }

    public async close() {
        await super.stop();
    }

    public async getCurrentState() {
        await super.send("OnRequestCurrentState");
    }
}
