import { HubConnectionProvider, HubMessage } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class VehicleDepartureMessage extends HubMessage<VehicleDepartureMessage> {
    jobId!: number;
    driverId!: number;
    vehicleId!: number;
    actualDeparture!: Date;
    plannedDeparture!: Date;

    public override overrideFromJSON(data: any): VehicleDepartureMessage {
        const result = new VehicleDepartureMessage();
        result.jobId = data["jobId"];
        result.driverId = data["driverId"];
        result.vehicleId = data["vehicleId"];
        result.actualDeparture = new Date(data["actualDeparture"].toString());
        result.plannedDeparture = new Date(data["plannedDeparture"].toString());
        return result;
    }
}
export type OnVehicleDeparted = (message: VehicleDepartureMessage) => void;

export class VehicleDepartureRemovedMessage extends HubMessage<VehicleDepartureRemovedMessage> {
    jobId!: number;
    driverId!: number;
    vehicleId!: number;
    plannedDeparture!: Date;

    public override overrideFromJSON(data: any): VehicleDepartureRemovedMessage {
        const result = new VehicleDepartureRemovedMessage();
        result.jobId = data["jobId"];
        result.driverId = data["driverId"];
        result.vehicleId = data["vehicleId"];
        result.plannedDeparture = new Date(data["plannedDeparture"].toString());
        return result;
    }
}
export type OnVehicleDepartureRemoved = (message: VehicleDepartureRemovedMessage) => void;

export class NewInProgressMonitoredJobAddedHubMessage extends HubMessage<NewInProgressMonitoredJobAddedHubMessage> {
    monitoredJob!: api.MonitoredJobViewModel;

    public override overrideFromJSON(data: any): NewInProgressMonitoredJobAddedHubMessage {
        const result = new NewInProgressMonitoredJobAddedHubMessage();
        result.monitoredJob = api.MonitoredJobViewModel.fromJS(data["monitoredJob"]);
        return result;
    }
}
export type OnInProgressNewMonitoredJobAdded = (message: NewInProgressMonitoredJobAddedHubMessage) => void;

export class VehicleDeparturesHub extends HubConnectionProvider {
    constructor() {
        super("departures-to-jobs-hub");
    }
    public async openVehicleDeparted(onChange: OnVehicleDeparted, onChangeRemoved: OnVehicleDepartureRemoved) {
        await super.onMessage(VehicleDepartureMessage, "VehicleDeparted", onChange);
        await super.onMessage(VehicleDepartureRemovedMessage, "VehicleDepartureRemoved", onChangeRemoved);
        await super.start();

        return this;
    }

    public async openInProgressNewMonitoredJobAdded(onChange: OnInProgressNewMonitoredJobAdded) {
        await super.onMessage(NewInProgressMonitoredJobAddedHubMessage, "NewInProgressMonitoredJobAdded", onChange);
        await super.start();

        return this;
    }

    public async close() {
        await super.stop();
    }
}
