type PrioritisedType = { id: number; code: number };

export function findNeighbours(prioritisedTypes: PrioritisedType[], code: number, currentType?: PrioritisedType): { before?: PrioritisedType; after?: PrioritisedType } {
    return (
        prioritisedTypes
            .filter((type) => type.id !== currentType?.id)
            // find codes that come closest after and before the given code in model.code
            .reduce<{ before?: PrioritisedType; after?: PrioritisedType }>((acc, typeGroup) => {
                const movingDown = currentType != null && currentType.code < code;

                if (typeGroup.code < code) {
                    acc.before = typeGroup;
                } else if (movingDown && typeGroup.code == code) {
                    acc.before = typeGroup;
                } else if (!movingDown && typeGroup.code == code) {
                    acc.after = typeGroup;
                } else if (acc.after == null && typeGroup.code > code) {
                    acc.after = typeGroup;
                }

                return acc;
            }, {})
    );
}
