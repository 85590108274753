import { inject, ref, Ref } from "vue";
import { api } from "@/api-client";
import { GlobalNavbarNotifier } from "@/plugins/GlobalNavbarNotifier";
import { VehiclesOnLowBatteryHubMessage } from "@/features/shared/signalR/GlobalNotificationsHub";

export class Injectables {
    public static useGlobalNavbarNotifierInstance = function (): GlobalNavbarNotifier | undefined {
        return inject<GlobalNavbarNotifier>(GlobalNavbarNotifier.INSTANCE_KEY);
    };

    // Publicly inaccessible vehicles

    public static usePubliclyInaccessibleVehiclesNotifications = function (): Ref<boolean> {
        return inject(GlobalNavbarNotifier.PUBLICLY_INACCESSIBLE_VEHICLES_MESSAGE_KEY, ref<boolean>(false));
    };

    // Vehicles on low battery

    public static useVehiclesOnLowBatteryNotifications = function (): Ref<VehiclesOnLowBatteryHubMessage> {
        return inject(GlobalNavbarNotifier.VEHICLES_ON_LOW_BATTERY_MESSAGE_KEY, ref<VehiclesOnLowBatteryHubMessage>(new VehiclesOnLowBatteryHubMessage()));
    };

    // External service interruptions

    public static useExternalServiceInterruptionsNotifications = function (): Ref<boolean> {
        return inject(GlobalNavbarNotifier.EXTERNAL_SERVICE_INTERRUPTIONS_MESSAGE_KEY, ref<boolean>(false));
    };

    // Expired updates

    public static useExpiredUpdatesNotifications = function (): Ref<api.ExternalServiceOperationName[]> {
        return inject(GlobalNavbarNotifier.EXPIRED_UPDATES_MESSAGE_KEY, ref<api.ExternalServiceOperationName[]>([]));
    };

    // Expiring reroutes
    public static useExpiringReroutesNotifications = function (): Ref<boolean> {
        return inject(GlobalNavbarNotifier.EXPIRING_REROUTES_MESSAGE_KEY, ref<boolean>(false));
    };

    // Inactive alcometers
    public static useInactiveAlcometersNotifications = function (): Ref<boolean> {
        return inject(GlobalNavbarNotifier.INACTIVE_ALCOMETERS_MESSAGE_KEY, ref<boolean>(false));
    };
}
