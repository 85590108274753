import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { api } from "@/api-client";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import { LocationQuery } from "vue-router";

export interface IPendingJobsFilters extends IPaginationWithSorting<PendingJobsColumns> {
    workTypes: string[];
    vehicleTypes: string[];
}

export enum PendingJobsColumns {
    Departure = "valjumine",
    Driver = "juht",
    Line = "liin",
    Vehicle = "soiduk",
    BatteryCharge = "akunait",
    TechnicalCondition = "tehnoseisund",
    WorkType = "tooliik",
}

export class PendingJobsFilters extends ListFiltersWithSortedPaging<PendingJobsColumns> implements IPendingJobsFilters {
    workTypes: string[] = [];
    vehicleTypes: string[] = [];

    static NoVehicleType = "Sõidukiliik puudub";

    constructor(data?: Partial<IPendingJobsFilters>) {
        super(data);
        this.workTypes = data?.workTypes ?? [];
        this.vehicleTypes = data?.vehicleTypes ?? [];
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.workTypes = query.tooliik ? String(query.tooliik).split(",") : [];
        this.vehicleTypes = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
    }

    createQuery() {
        return {
            ...super.createQuery(),
            tooliik: this.workTypes.length ? this.workTypes : undefined,
            soidukiliik: this.vehicleTypes.length ? this.vehicleTypes : undefined,
        };
    }
    clearAllFilters(): PendingJobsFilters {
        return new PendingJobsFilters();
    }

    applyOn(jobs: api.IPendingJobViewModel[]): api.IPendingJobViewModel[] {
        jobs = this.filterByWorkType(jobs);
        jobs = this.filterByVehicleType(jobs);
        return jobs;
    }
    filterByWorkType(jobs: api.IPendingJobViewModel[]) {
        return jobs.filter((job) => (this.workTypes.length ? this.workTypes.includes(workTypeTranslator.translate(job.workType)) : true));
    }
    filterByVehicleType(jobs: api.IPendingJobViewModel[]) {
        return jobs.filter((job) => (this.vehicleTypes.length ? this.vehicleTypes.includes(job.vehicle?.type.label() ?? PendingJobsFilters.NoVehicleType) : true));
    }
}
