import script from "./DeparturesTableView.vue?vue&type=script&lang=ts&setup=true"
export * from "./DeparturesTableView.vue?vue&type=script&lang=ts&setup=true"

import "./DeparturesTableView.vue?vue&type=style&index=0&id=09008de6&lang=scss"

const __exports__ = script;

export default __exports__
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCheckbox,QIcon,QTable,QTr,QTh,QTd});
