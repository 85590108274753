import { LocationQuery } from "vue-router";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import { DeparturesListFilters, IDeparturesListFilters } from "@/features/preparation/departures/DeparturesListFilters";
import { api } from "@/api-client";

export interface IRegularDeparturesListFilters extends IDeparturesListFilters<RegularDeparturesListColumns> {
    lineNumbers: string[];
    workTypes: string[];
}

export enum RegularDeparturesListColumns {
    PlannedDeparture = "valjumine",
    Driver = "juht",
    DriverDepartmentAndGroup = "osakond/grupp",
    Line = "liin",
    Vehicle = "soiduk",
    BatteryCharge = "akunait",
    EntranceInspection = "labipaas",
    HealthInspection = "tervisekontroll",
    ExitInspection = "liinile_valjumine",
    StopName = "asukoht",
    WorkType = "tooliik",
    TechnicalCondition = "tehnoseisund",
}

export class RegularDeparturesListFilters extends DeparturesListFilters<RegularDeparturesListColumns> implements IRegularDeparturesListFilters {
    lineNumbers: string[] = [];
    workTypes: string[] = [];

    constructor(data?: Partial<IRegularDeparturesListFilters>) {
        super(data);
        this.lineNumbers = data?.lineNumbers ?? [];
        this.workTypes = data?.workTypes ?? [];
    }

    static readonly STORAGE_KEY = "RegularDeparturesListFilters";

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.lineNumbers = query.liininr ? String(query.liininr).split(",") : [];
        this.workTypes = query.tooliik ? String(query.tooliik).split(",") : [];
    }

    createQuery() {
        return {
            ...super.createQuery(),
            liininr: this.lineNumbers.length ? this.lineNumbers : undefined,
            tooliik: this.workTypes.length ? this.workTypes : undefined,
        };
    }

    clearAllFilters() {
        sessionStorage.removeItem(RegularDeparturesListFilters.STORAGE_KEY);
        return new RegularDeparturesListFilters({ sortBy: RegularDeparturesListColumns.PlannedDeparture });
    }

    applyOn(departures: api.RegularDepartureViewModel[]): api.RegularDepartureViewModel[] {
        return (super.applyOn(departures) as api.RegularDepartureViewModel[])
            .filter((departure) => (this.lineNumbers.length ? this.lineNumbers.some((lineNumber) => departure.lineNumbers.includes(lineNumber)) : true))
            .filter((departure) => (this.workTypes.length ? this.workTypes.includes(workTypeTranslator.translate(departure.servingDriverAndVehicle.workType)) : true));
    }

    createRemoveWarningPartialFilter(warning: string): Partial<IRegularDeparturesListFilters> {
        if (warning === DeparturesListFilters.HAS_FAULTY_VEHICLE) return { hasFaultyVehicle: undefined };
        if (warning === DeparturesListFilters.HAS_NEGATIVE_HEALTH_INSPECTION) return { hasNegativeHealthInspection: undefined };
        if (warning === DeparturesListFilters.NOT_DEPARTED) return { notDeparted: undefined };

        return {};
    }
}
