import { api } from "@/api-client";
import { LocationQuery } from "vue-router";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import vehicleCategoryTranslator from "@/features/VehicleCategoryTranslator";
import vehicleBodyTypes from "@/features/VehicleBodyTypes";
import { FromTo } from "moment-timezone";
import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";

export abstract class JobOrderFilterOption {
    abstract options: readonly string[];
}
export class JobOrderVehicleTypeFilterOptions extends JobOrderFilterOption {
    options: readonly string[] = [];

    constructor() {
        super();

        const categories = Object.values(api.VehicleCategory).filter((x): x is api.VehicleCategory => typeof x !== "string");

        const product = vehicleBodyTypes
            .all()
            .map((bodyType) => {
                return categories.map((category) => {
                    return new api.VehicleTypeViewModel({ category: category, type: bodyType });
                });
            })
            .flat();

        this.options = product.map((x) => x.label()).sort();
    }

    static fromOptions(value: string[]) {
        // Value could either be 'Category' OR 'Category (BodyType)'
        // First remove all whitespaces 'Category (BodyType)' => 'Category(BodyType)'
        // Then if BodyType is present, split by '(' and ')' to get the category and body type => ['Category', 'BodyType']
        const selected = value.map((vt) => vt.replace(" ", "")).map((vt) => (vt.includes("(") ? ([vt.split("(")[0], vt.split("(")[1].slice(0, -1)] as const) : ([vt, undefined] as const)));
        return selected.map(([category, bodyType]) => {
            return new api.JobOrderVehicleTypeFilterItem({
                category: vehicleCategoryTranslator.stringToVehicleCategory(category),
                bodyType: bodyType,
            });
        });
    }

    static toOption(value: api.JobOrderVehicleTypeFilterItem) {
        return new api.VehicleTypeViewModel({ category: value.category, type: value.bodyType }).label();
    }
}

export class JobOrderTypeFilterOptions extends JobOrderFilterOption {
    options = ["Muu sõidukorraldus", "Liini tihendamine"] as const;

    constructor() {
        super();
    }

    static fromOptions(value: string[]): api.JobOrderType[] {
        return value.map((x) => {
            switch (x) {
                case "Muu sõidukorraldus":
                    return api.JobOrderType.JobOrder;
                case "Liini tihendamine":
                    return api.JobOrderType.LineInsertion;
                default:
                    throw new Error(`Unknown job order type ${x}`);
            }
        });
    }

    static toOption(value: api.JobOrderType): string {
        switch (value) {
            case api.JobOrderType.JobOrder:
                return "Muu sõidukorraldus";
            case api.JobOrderType.LineInsertion:
                return "Liini tihendamine";
            default:
                throw new Error(`Unknown job order type ${value}`);
        }
    }
}

export class WorkTypeFilterOptions extends JobOrderFilterOption {
    options: readonly string[] = [];

    constructor() {
        super();

        this.options = Object.values(workTypeTranslator.all)
            .filter((x) => x != null)
            .map((x) => workTypeTranslator.translate(x));
    }

    static fromOptions(value: string[]) {
        return value.map((wt) => workTypeTranslator.all[wt as keyof typeof workTypeTranslator.all] as api.WorkType);
    }

    static toOption(value: api.WorkType) {
        return workTypeTranslator.translate(value);
    }
}

export class JobOrderDepartmentFilterOptions extends JobOrderFilterOption {
    options: readonly string[] = [];
    static NoDepartmentOption = "Muud";

    constructor(departments?: readonly string[]) {
        super();
        this.options = [...(departments ?? []), JobOrderDepartmentFilterOptions.NoDepartmentOption];
    }

    static fromOptions(value: string[]) {
        return value;
    }

    static toOption(value: string) {
        return value;
    }
}

export class JobOrderDriverGroupFilterOptions extends JobOrderFilterOption {
    options: readonly string[] = [];
    static NoGroupOption = "-";

    constructor(driverGroups?: readonly string[]) {
        super();
        this.options = [...(driverGroups ?? []), JobOrderDriverGroupFilterOptions.NoGroupOption];
    }

    static fromOptions(value: string[]) {
        return value;
    }

    static toOption(value: string) {
        return value;
    }
}

export type JobOrderFilterOptions = {
    vehicleTypes: JobOrderVehicleTypeFilterOptions;
    types: JobOrderTypeFilterOptions;
    workTypes: WorkTypeFilterOptions;
    departments: JobOrderDepartmentFilterOptions;
    groups: JobOrderDriverGroupFilterOptions;
};

export interface IJobOrderFilters extends IPaginationWithSorting<JobOrderListColumns> {
    vehicleTypes: api.JobOrderVehicleTypeFilterItem[];
    types: api.JobOrderType[];
    workTypes: api.WorkType[];
    departments: string[];
    groups: string[];
    searchString: string;
    day?: FromTo | string;
}

export enum JobOrderListColumns {
    RunStart = "valjub",
    RunEnd = "saabub",
    Day = "sisestatud",
    TotalKm = "km",
    Driver = "juht",
    Vehicle = "Vehicle",
    StartLocation = "asukoht",
    EndLocation = "sihtkoht",
    DepartmentAndDriverGroup = "osakond_grupp",
    WorkType = "liik",
}

export class JobOrderFilters extends ListFiltersWithSortedPaging<JobOrderListColumns> {
    vehicleTypes: api.JobOrderVehicleTypeFilterItem[];
    types: api.JobOrderType[];
    workTypes: api.WorkType[];
    departments: string[];
    groups: string[];
    searchString = "";
    day?: FromTo | string;

    toApiSort(): api.SortJobOrdersBy {
        switch (this.sortBy) {
            case JobOrderListColumns.RunStart:
                return api.SortJobOrdersBy.RunStart;
            case JobOrderListColumns.RunEnd:
                return api.SortJobOrdersBy.RunEnd;
            case JobOrderListColumns.Day:
                return api.SortJobOrdersBy.Day;
            case JobOrderListColumns.TotalKm:
                return api.SortJobOrdersBy.TotalKm;
            case JobOrderListColumns.Driver:
                return api.SortJobOrdersBy.Driver;
            case JobOrderListColumns.Vehicle:
                return api.SortJobOrdersBy.Vehicle;
            case JobOrderListColumns.StartLocation:
                return api.SortJobOrdersBy.StartLocation;
            case JobOrderListColumns.EndLocation:
                return api.SortJobOrdersBy.EndLocation;
            case JobOrderListColumns.DepartmentAndDriverGroup:
                return api.SortJobOrdersBy.DepartmentAndDriverGroup;
            case JobOrderListColumns.WorkType:
                return api.SortJobOrdersBy.WorkType;
            default:
                return api.SortJobOrdersBy.RunStart;
        }
    }

    static readonly STORAGE_KEY = "JobOrderFilters";

    constructor(data?: Partial<IJobOrderFilters>) {
        super(data);
        this.rowsPerPage = data?.rowsPerPage ?? DEFAULT_PAGE_SIZE;
        this.descending = data?.descending ?? true;
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.types = data?.types ?? [];
        this.workTypes = data?.workTypes ?? [];
        this.departments = data?.departments ?? [];
        this.groups = data?.groups ?? [];
        this.searchString = data?.searchString ?? "";
        this.day = data?.day;
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.searchString = query.otsing ? String(query.otsing) : "";
        this.vehicleTypes = query.soidukiliik ? JobOrderVehicleTypeFilterOptions.fromOptions(String(query.soidukiliik).split(",")) : [];
        this.types = query.liik ? JobOrderTypeFilterOptions.fromOptions(String(query.liik).split(",")) : [];
        this.workTypes = query.tooliik ? WorkTypeFilterOptions.fromOptions(String(query.tooliik).split(",")) : [];
        this.departments = query.osakond ? JobOrderDepartmentFilterOptions.fromOptions(String(query.osakond).split(",")) : [];
        this.groups = query.grupp ? JobOrderDriverGroupFilterOptions.fromOptions(String(query.grupp).split(",")) : [];
        this.day = { from: query.alates as string, to: query.kuni as string };
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString !== "" ? this.searchString : undefined,
            soidukiliik: this.vehicleTypes.length ? this.vehicleTypes.map(JobOrderVehicleTypeFilterOptions.toOption) : undefined,
            liik: this.types.length ? this.types.map(JobOrderTypeFilterOptions.toOption) : undefined,
            tooliik: this.workTypes.length ? this.workTypes.map(WorkTypeFilterOptions.toOption) : undefined,
            osakond: this.departments.length ? this.departments.map(JobOrderDepartmentFilterOptions.toOption) : undefined,
            grupp: this.groups.length ? this.groups.map(JobOrderDriverGroupFilterOptions.toOption) : undefined,
            alates: this.getRunStartFilter()?.from ? this.getRunStartFilter()?.from?.toString() : undefined,
            kuni: this.getRunStartFilter()?.to ? this.getRunStartFilter()?.to?.toString() : undefined,
        };
    }

    getRunStartFilter() {
        return typeof this.day === "string" ? { from: this.day, to: this.day } : this.day;
    }

    clearFilters() {
        sessionStorage.removeItem(JobOrderFilters.STORAGE_KEY);
        return new JobOrderFilters();
    }
}
