import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { LocationQuery } from "vue-router";
import { api } from "@/api-client";
import moment from "moment-timezone";

export enum InsuranceContractsListColumns {
    VehicleType = "soidukikategooria",
    VehicleMark = "soidukimark",
    ValidFrom = "kehtivalates",
    ValidTo = "kehtivkuni",
    InsuranceCompany = "kindlustusfirma",
}

export type InsuranceContractsListFilterOptions = {
    vehicleTypeOptions: string[];
    vehicleMarkOptions: string[];
    insuranceCompanyOptions: string[];
    insuranceContractStatuses: string[];
};

export interface IInsuranceContractsListFilters extends IPaginationWithSorting<InsuranceContractsListColumns> {
    vehicleTypes: string[];
    vehicleMarks: string[];
    insuranceContractStatuses: string[];
    insuranceCompanies: string[];

    clearAllFilters(clearSearch: boolean): InsuranceContractsListFilters;
}

export class InsuranceContractsListFilters extends ListFiltersWithSortedPaging<InsuranceContractsListColumns> {
    vehicleTypes: string[] = [];
    vehicleMarks: string[] = [];
    insuranceContractStatuses: string[] = [];
    insuranceCompanies: string[] = [];

    static readonly STORAGE_KEY = "InsuranceContractsListFilters";

    static readonly VALID = "Kehtiv";
    static readonly EXPIRED = "Aegunud";

    constructor(data?: Partial<IInsuranceContractsListFilters>) {
        super(data);
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.vehicleMarks = data?.vehicleMarks ?? [];
        this.insuranceContractStatuses = data?.insuranceContractStatuses ?? [];
        this.insuranceCompanies = data?.insuranceCompanies ?? [];
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.vehicleTypes = query.soidukikategooria ? String(query.soidukikategooria).split(",") : [];
        this.vehicleMarks = query.soidukimark ? String(query.soidukimark).split(",") : [];
        this.insuranceContractStatuses = query.kindlustuseolek ? String(query.kindlustuseolek).split(",") : [];
        this.insuranceCompanies = query.kindlustusfirma ? String(query.kindlustusfirma).split(",") : [];
    }

    createQuery() {
        return {
            ...super.createQuery(),
            soidukikategooria: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            soidukimark: this.vehicleMarks.length ? this.vehicleMarks : undefined,
            kindlustuseolek: this.insuranceContractStatuses.length ? this.insuranceContractStatuses : undefined,
            kindlustusfirma: this.insuranceCompanies.length ? this.insuranceCompanies : undefined,
        };
    }

    clearAllFilters() {
        sessionStorage.removeItem(InsuranceContractsListFilters.STORAGE_KEY);
        return new InsuranceContractsListFilters();
    }

    applyOn(reroutes: api.IInsuranceContractViewModel[]): api.IInsuranceContractViewModel[] {
        return reroutes
            .filter((insuranceContract) => filterByVehicleType(this.vehicleTypes, insuranceContract))
            .filter((insuranceContract) => filterByVehicleMark(this.vehicleMarks, insuranceContract))
            .filter((insuranceContract) => filterByInsuranceStatuses(this.insuranceContractStatuses, insuranceContract))
            .filter((insuranceContract) => filterByInsuranceCompanyName(this.insuranceCompanies, insuranceContract));

        function filterByVehicleType(vehicleTypes: string[], insuranceContract: api.IInsuranceContractViewModel): boolean {
            if (!vehicleTypes.length) {
                return true;
            }
            return insuranceContract.vehicleTypes.some((x) => vehicleTypes.includes(x.label()));
        }

        function filterByVehicleMark(vehicleMarks: string[], insuranceContract: api.IInsuranceContractViewModel): boolean {
            if (!vehicleMarks.length) {
                return true;
            }
            return insuranceContract.vehicleMarks.some((x) => vehicleMarks.includes(x));
        }

        function filterByInsuranceStatuses(insuranceContractStatuses: string[], insuranceContract: api.IInsuranceContractViewModel): boolean {
            const filterByValidStatus = insuranceContractStatuses.some((x) => x === InsuranceContractsListFilters.VALID);
            const filterByInValidStatus = insuranceContractStatuses.some((x) => x === InsuranceContractsListFilters.EXPIRED);

            if (!insuranceContractStatuses.length || (filterByValidStatus && filterByInValidStatus)) {
                return true;
            }

            const validTo = moment.utc(insuranceContract.validTo).toDate();
            const currentDate = moment().utc().toDate();

            const isInvalidInsurance = validTo < currentDate;
            const isValidInsurance = validTo >= currentDate;

            if (filterByValidStatus) {
                return insuranceContract.validTo == null || isValidInsurance;
            }
            if (filterByInValidStatus) {
                return isInvalidInsurance;
            }

            return true;
        }

        function filterByInsuranceCompanyName(insuranceCompanies: string[], insuranceContract: api.IInsuranceContractViewModel): boolean {
            if (!insuranceCompanies.length) {
                return true;
            }
            return insuranceCompanies.includes(insuranceContract.insuranceCompanyName);
        }
    }
}
