import { LocationQuery } from "vue-router";
import { api } from "@/api-client";
import ReserveVehicleViewModel = api.ReserveVehicleViewModel;
import { hasValue } from "@/helpers/Utils";
import { formatTime } from "@/helpers/DateFormatter";
import moment from "moment-timezone";
import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import HasReserveJobInfoViewModel = api.HasReserveJobInfoViewModel;
export interface IReserveVehicleListFilters extends IPaginationWithSorting<ReserveVehiclesListColumns> {
    searchString: string | undefined;
    vehicleTypes: string[];
    marks: string[];
    models: string[];
    divisions: string[];
    technicalConditions: string[];
    vehicleFreeTimeFrom: string | undefined;
    vehicleFreeTimeTo: string | undefined;
    workTypes: string[];
    withBatteryChargeOnly: "true" | "false" | undefined;
}

export enum ReserveVehiclesListColumns {
    Vehicle = "soiduk",
    RegistrationNumber = "regnr",
    MarkAndModel = "mark_mudel",
    Division = "divisjon",
    TechnicalCondition = "korrasolek",
    BatteryCharge = "akunait",
}

export class ReserveVehiclesListFilters extends ListFiltersWithSortedPaging<ReserveVehiclesListColumns> implements IReserveVehicleListFilters {
    searchString: string | undefined = undefined;
    vehicleTypes: string[] = [];
    marks: string[] = [];
    models: string[] = [];
    divisions: string[] = [];
    technicalConditions: string[] = [];
    vehicleFreeTimeFrom: string | undefined;
    vehicleFreeTimeTo: string | undefined;
    workTypes: string[] = [];
    withBatteryChargeOnly: "true" | "false" | undefined = undefined;

    static readonly NO_VEHICLE = "Sõiduk puudub";
    static readonly NO_DIVISION = "Osakond puudub";
    static readonly NO_MARK = "Mark puudub";
    static readonly NO_MODEL = "Mudel puudub";
    static readonly NO_WORK_TYPE = "Töö puudub";
    static readonly STORAGE_KEY = "ReserveVehiclesListFilters";

    constructor(data?: Partial<IReserveVehicleListFilters>) {
        super(data);
        this.searchString = data?.searchString;
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.marks = data?.marks ?? [];
        this.models = data?.models ?? [];
        this.divisions = data?.divisions ?? [];
        this.technicalConditions = data?.technicalConditions ?? [];
        this.vehicleFreeTimeFrom = data?.vehicleFreeTimeFrom;
        this.vehicleFreeTimeTo = data?.vehicleFreeTimeTo;
        this.workTypes = data?.workTypes ?? [];
        this.withBatteryChargeOnly = data?.withBatteryChargeOnly ?? undefined;
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.searchString = query.otsing ? String(query.otsing) : undefined;
        this.vehicleTypes = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
        this.marks = query.mark ? String(query.mark).split(",") : [];
        this.models = query.mudel ? String(query.mudel).split(",") : [];
        this.divisions = query.divisjon ? String(query.divisjon).split(",") : [];
        this.technicalConditions = query.korrasolek ? String(query.korrasolek).split(",") : [];
        this.vehicleFreeTimeFrom = query.vaba_alates ? (query.vaba_alates as string) : undefined;
        this.vehicleFreeTimeTo = query.vaba_kuni ? (query.vaba_kuni as string) : undefined;
        this.workTypes = query.tooliik ? String(query.tooliik).split(",") : [];
        this.withBatteryChargeOnly = query.akunait ? (query.akunait as "true" | "false") : undefined;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString?.length ? this.searchString : undefined,
            soidukiliik: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            mark: this.marks.length ? this.marks : undefined,
            mudel: this.models.length ? this.models : undefined,
            divisjon: this.divisions.length ? this.divisions : undefined,
            korrasolek: this.technicalConditions.length ? this.technicalConditions : undefined,
            vaba_alates: this.vehicleFreeTimeFrom?.length ? this.vehicleFreeTimeFrom : undefined,
            vaba_kuni: this.vehicleFreeTimeTo?.length ? this.vehicleFreeTimeTo : undefined,
            tooliik: this.workTypes.length ? this.workTypes : undefined,
            akunait: this.withBatteryChargeOnly ? this.withBatteryChargeOnly : undefined,
        };
    }

    clearAllFilters() {
        sessionStorage.removeItem(ReserveVehiclesListFilters.STORAGE_KEY);
        return new ReserveVehiclesListFilters();
    }

    applyOn(vehicles: Array<ReserveVehicleViewModel>): Array<ReserveVehicleViewModel> {
        return vehicles
            .filter((vehicle) => filterByWorkType(this.workTypes, vehicle))
            .filter((vehicle) => filterByBatteryCharge(this.withBatteryChargeOnly, vehicle))
            .filter((vehicle) => filterVehicleType(this.vehicleTypes, vehicle))
            .filter((vehicle) => filterByMark(this.marks, vehicle))
            .filter((vehicle) => filterByModel(this.models, vehicle))
            .filter((vehicle) => filterByDivision(this.divisions, vehicle))
            .filter((vehicle) => filterByTechnicalCondition(this.technicalConditions, vehicle))
            .filter((vehicle) => filterBySearchString(this.searchString, vehicle))
            .filter((vehicle) => filterByVehicleFreeTime(this.vehicleFreeTimeFrom, this.vehicleFreeTimeTo, vehicle));

        function filterByWorkType(workTypes: string[], model: HasReserveJobInfoViewModel): boolean {
            if (!workTypes.length) {
                return true;
            }
            return model.jobs.length ? model.jobs.some((j) => workTypes.includes(workTypeTranslator.translate(j.workType))) : workTypes.includes(ReserveVehiclesListFilters.NO_WORK_TYPE);
        }
        function filterVehicleType(vehicleTypes: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!vehicleTypes.length) {
                return true;
            }
            return vehicleTypes.includes(vehicle?.type == null ? ReserveVehiclesListFilters.NO_VEHICLE : vehicle.type.label());
        }

        function filterByMark(marks: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!marks.length) {
                return true;
            }

            return hasValue(vehicle.mark) ? marks.includes(vehicle.mark) : marks.includes(ReserveVehiclesListFilters.NO_MARK);
        }

        function filterByModel(models: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!models.length) {
                return true;
            }

            return hasValue(vehicle.model) ? models.includes(vehicle.model) : models.includes(ReserveVehiclesListFilters.NO_MODEL);
        }

        function filterByDivision(divisions: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!divisions.length) {
                return true;
            }
            return vehicle.division == null ? divisions.includes(ReserveVehiclesListFilters.NO_DIVISION) : divisions.includes(vehicle.division);
        }

        function filterByTechnicalCondition(technicalConditions: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!technicalConditions.length) {
                return true;
            }
            return technicalConditions.includes(vehicle.repairStatus.technicalConditionLabel());
        }

        function filterBySearchString(searchString: string | undefined, vehicle: ReserveVehicleViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }
            return (vehicle?.garageNumber?.toLowerCase().includes(searchString.toLowerCase()) || vehicle?.registrationNumber?.toLowerCase().includes(searchString.toLowerCase())) ?? false;
        }

        function filterByVehicleFreeTime(freeTimeFrom: string | undefined, freeTimeTo: string | undefined, vehicle: ReserveVehicleViewModel): boolean {
            if (!vehicle.jobs.length) {
                return true;
            }

            for (let i = 0; i < vehicle.jobs.length; i++) {
                const nextJob = i != vehicle.jobs.length - 1 ? vehicle.jobs[i + 1] : undefined;
                const previousJob = i != 0 ? vehicle.jobs[i - 1] : undefined;
                if (hasFreeTimeForJob(freeTimeFrom, freeTimeTo, vehicle.jobs[i], previousJob, nextJob)) {
                    return true;
                }
            }

            return false;

            function hasFreeTimeForJob(
                freeTimeFrom: string | undefined,
                freeTimeTo: string | undefined,
                job: api.ReserveJobInfoViewModel,
                previousJob: api.ReserveJobInfoViewModel | undefined,
                nextJob: api.ReserveJobInfoViewModel | undefined
            ): boolean {
                const jobStart = formatTime(job.runStart);
                const jobEnd = formatTime(job.runEnd);

                const isJobStartSameDay = moment(job.runStart).isSame(moment(), "day");
                const isJobEndSameDay = moment(job.runEnd).isSame(moment(), "day");

                const hasNextJob = nextJob != undefined;
                const hasPreviousJob = previousJob != undefined;

                const nextJobStart = hasNextJob ? formatTime(nextJob.runStart) : undefined;
                const previousJobEnd = hasPreviousJob ? formatTime(previousJob.runEnd) : undefined;

                const isNextJobStartNextDay = hasNextJob && moment(nextJob.runStart).isSame(moment().add(1, "day"), "day");
                const isPreviousJobEndPreviousDay = hasPreviousJob && moment(previousJob.runEnd).isSame(moment().subtract(1, "day"), "day");

                if (!hasPreviousJob && !hasNextJob && (hasFreeTimeBeforeJob(freeTimeFrom, freeTimeTo) || hasFreeTimeAfterJob(freeTimeFrom, freeTimeTo))) {
                    return true;
                }
                if (hasPreviousJob && !hasNextJob && hasFreeTimeAfterJob(freeTimeFrom, freeTimeTo)) {
                    return true;
                }
                if (!hasPreviousJob && hasNextJob && (hasFreeTimeBeforeJob(freeTimeFrom, freeTimeTo) || hasFreeTimeBetweenCurrentAndNextJob(freeTimeFrom, freeTimeTo))) {
                    return true;
                }
                if (hasPreviousJob && hasNextJob && (hasFreeTimeBetweenCurrentAndPreviousJob(freeTimeFrom, freeTimeTo) || hasFreeTimeBetweenCurrentAndNextJob(freeTimeFrom, freeTimeTo))) {
                    return true;
                }
                return false;

                function hasFreeTimeBeforeJob(freeTimeFrom: string | undefined, freeTimeTo: string | undefined): boolean {
                    return hasFreeTimeBeforeJob(freeTimeFrom) && hasFreeTimeBeforeJob(freeTimeTo);
                    function hasFreeTimeBeforeJob(freeTime: string | undefined): boolean {
                        return !freeTime || (isJobStartSameDay && jobStart >= freeTime);
                    }
                }
                function hasFreeTimeAfterJob(freeTimeFrom: string | undefined, freeTimeTo: string | undefined): boolean {
                    return hasFreeTimeAfterJob(freeTimeFrom) && hasFreeTimeAfterJob(freeTimeTo);
                    function hasFreeTimeAfterJob(freeTime: string | undefined): boolean {
                        return !freeTime || (isJobEndSameDay && jobEnd <= freeTime);
                    }
                }
                function hasFreeTimeBetweenCurrentAndPreviousJob(freeTimeFrom: string | undefined, freeTimeTo: string | undefined): boolean {
                    return hasFreeTimeBeforeJob(freeTimeFrom, freeTimeTo) && hasFreeTimeBetweenCurrentAndPreviousJob(freeTimeFrom) && hasFreeTimeBetweenCurrentAndPreviousJob(freeTimeTo);
                    function hasFreeTimeBetweenCurrentAndPreviousJob(freeTime: string | undefined): boolean {
                        return !freeTime || (hasPreviousJob && previousJobEnd! <= freeTime) || isPreviousJobEndPreviousDay;
                    }
                }
                function hasFreeTimeBetweenCurrentAndNextJob(freeTimeFrom: string | undefined, freeTimeTo: string | undefined): boolean {
                    return hasFreeTimeAfterJob(freeTimeFrom, freeTimeTo) && hasFreeTimeBetweenCurrentAndNextJob(freeTimeFrom) && hasFreeTimeBetweenCurrentAndNextJob(freeTimeTo);
                    function hasFreeTimeBetweenCurrentAndNextJob(freeTime: string | undefined): boolean {
                        return !freeTime || (hasNextJob && nextJobStart! >= freeTime) || isNextJobStartNextDay;
                    }
                }
            }
        }

        function filterByBatteryCharge(withBatteryChargeOnly: string | undefined, vehicle: ReserveVehicleViewModel) {
            return withBatteryChargeOnly === "true" ? vehicle.batteryCharge != null : withBatteryChargeOnly === "false" ? vehicle.batteryCharge == null : true;
        }
    }
}
