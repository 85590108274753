import { api } from "@/api-client";
import moment from "moment-timezone";

class JobsMonitoringHelpers {
    public sortByDeviation = (a: api.IDeviationModel, b: api.IDeviationModel) => {
        return a.deviationInMinutes - b.deviationInMinutes;
    };

    public getDeviationAsHoursAndMinutesAndSecondsString(deviation: api.IDeviationModel | null) {
        if (deviation == null) {
            return "";
        }

        return `${deviation.isEarly ? "-" : deviation.isLate ? "+" : ""} ${this.getMinutesAsTimespan(deviation.deviationInMinutes)}`;
    }

    public getMinutesAsTimespan(minutes: number) {
        const absoluteMinutes = Math.abs(minutes);
        const timeSpan = moment.duration(absoluteMinutes, "minutes");
        return `${Math.floor(timeSpan.asHours()).toFixed().padStart(2, "0")}:${String(timeSpan.minutes()).padStart(2, "0")}:00`;
    }
}

const jobsMonitoringHelpers = new JobsMonitoringHelpers();
export default jobsMonitoringHelpers;
