import { LocationQuery } from "vue-router";
import { IPaginationWithSorting, ListFiltersWithSortedPaging } from "@/components/ListFilters";
import { api } from "@/api-client";
import moment from "moment-timezone";
import { DEFAULT_PAGE_SIZE } from "@/shared/constants";

export interface IDeparturesListFilters<TColumns> extends IPaginationWithSorting<TColumns> {
    searchString: string | undefined;
    vehicleTypes: string[];
    stopNames: string[];
    plannedDepartureFrom: string | undefined;
    plannedDepartureTo: string | undefined;
    driverDepartment: string[];
    driverGroups: string[];
    withBatteryChargeOnly: "true" | "false" | undefined;
    hasFaultyVehicle: "true" | undefined;
    hasNegativeHealthInspection: "true" | undefined;
    notDeparted: "true" | undefined;
}

export abstract class DeparturesListFilters<TColumns> extends ListFiltersWithSortedPaging<TColumns> implements IDeparturesListFilters<TColumns> {
    searchString: string | undefined = undefined;
    vehicleTypes: string[] = [];
    stopNames: string[] = [];
    plannedDepartureFrom: string | undefined;
    plannedDepartureTo: string | undefined;
    driverDepartment: string[] = [];
    driverGroups: string[] = [];
    withBatteryChargeOnly: "true" | "false" | undefined = undefined;
    hasFaultyVehicle: "true" | undefined;
    hasNegativeHealthInspection: "true" | undefined;
    notDeparted: "true" | undefined;

    static readonly NO_VEHICLE = "Sõiduk puudub";
    static readonly HAS_FAULTY_VEHICLE = "Mittekorras sõiduk";
    static readonly HAS_NEGATIVE_HEALTH_INSPECTION = "Juht on joobes";
    static readonly NOT_DEPARTED = "Puuduvad andmed";

    public get warnings(): string[] {
        return [
            this.hasFaultyVehicle ? DeparturesListFilters.HAS_FAULTY_VEHICLE : null,
            this.hasNegativeHealthInspection ? DeparturesListFilters.HAS_NEGATIVE_HEALTH_INSPECTION : null,
            this.notDeparted ? DeparturesListFilters.NOT_DEPARTED : null,
        ].filter((warning) => warning != null);
    }

    protected constructor(data?: Partial<IDeparturesListFilters<TColumns>>) {
        super(data);
        this.rowsPerPage = data?.rowsPerPage ?? DEFAULT_PAGE_SIZE;
        this.searchString = data?.searchString ?? undefined;
        this.vehicleTypes = data?.vehicleTypes ?? [];
        this.stopNames = data?.stopNames ?? [];
        this.plannedDepartureFrom = data?.plannedDepartureFrom ?? undefined;
        this.plannedDepartureTo = data?.plannedDepartureTo ?? undefined;
        this.driverDepartment = data?.driverDepartment ?? [];
        this.driverGroups = data?.driverGroups ?? [];
        this.withBatteryChargeOnly = data?.withBatteryChargeOnly ?? undefined;
        this.hasFaultyVehicle = data?.hasFaultyVehicle ?? undefined;
        this.hasNegativeHealthInspection = data?.hasNegativeHealthInspection ?? undefined;
        this.notDeparted = data?.notDeparted ?? undefined;
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.searchString = query.otsing ? String(query.otsing) : undefined;
        this.vehicleTypes = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
        this.stopNames = query.asukoht ? String(query.asukoht).split(",") : [];
        this.plannedDepartureFrom = query.alates ? (query.alates as string) : undefined;
        this.plannedDepartureTo = query.kuni ? (query.kuni as string) : undefined;
        this.driverDepartment = query.osakond ? String(query.osakond).split(",") : [];
        this.driverGroups = query.grupp ? String(query.grupp).split(",") : [];
        this.withBatteryChargeOnly = query.akunait ? (query.akunait as "true" | "false") : undefined;
        this.hasFaultyVehicle = query.mittekorrassoiduk ? (query.mittekorrassoiduk as "true") : undefined;
        this.hasNegativeHealthInspection = query.joobesjuht ? (query.joobesjuht as "true") : undefined;
        this.notDeparted = query.andmedpuuduvad ? (query.andmedpuuduvad as "true") : undefined;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString?.length ? this.searchString : undefined,
            soidukiliik: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            asukoht: this.stopNames.length ? this.stopNames : undefined,
            alates: this.plannedDepartureFrom ? this.plannedDepartureFrom : undefined,
            kuni: this.plannedDepartureTo ? this.plannedDepartureTo : undefined,
            osakond: this.driverDepartment.length ? this.driverDepartment : undefined,
            grupp: this.driverGroups.length ? this.driverGroups : undefined,
            akunait: this.withBatteryChargeOnly ? this.withBatteryChargeOnly : undefined,
            mittekorrassoiduk: this.hasFaultyVehicle ? this.hasFaultyVehicle : undefined,
            joobesjuht: this.hasNegativeHealthInspection ? this.hasNegativeHealthInspection : undefined,
            andmedpuuduvad: this.notDeparted ? this.notDeparted : undefined,
        };
    }

    update(value: IDeparturesListFilters<TColumns>) {
        this.vehicleTypes = value.vehicleTypes;
        this.searchString = value.searchString;
        this.stopNames = value.stopNames;
        this.plannedDepartureFrom = value.plannedDepartureFrom;
        this.driverDepartment = value.driverDepartment;
        this.plannedDepartureTo = value.plannedDepartureTo;
        this.hasFaultyVehicle = value.hasFaultyVehicle;
        this.hasNegativeHealthInspection = value.hasNegativeHealthInspection;
        this.notDeparted = value.notDeparted;
        this.hasFaultyVehicle = value.hasFaultyVehicle;
        this.hasNegativeHealthInspection = value.hasNegativeHealthInspection;
        this.notDeparted = value.notDeparted;
    }

    applyOn(departures: api.DepartureViewModel[]): api.DepartureViewModel[] {
        departures = this.filterByPlannedDeparture(departures);

        return departures
            .filter((departure) =>
                this.withBatteryChargeOnly === "true"
                    ? departure.servingDriverAndVehicle.vehicle?.batteryCharge != null
                    : this.withBatteryChargeOnly === "false"
                    ? departure.servingDriverAndVehicle.vehicle?.batteryCharge == null
                    : true
            )
            .filter((departure) => {
                // when no warning filters set, don't filter anything. otherwise only show departures, where any of set filters match
                return !(this.hasFaultyVehicle === "true" || this.hasNegativeHealthInspection === "true" || this.notDeparted === "true")
                    ? true
                    : (this.hasFaultyVehicle === "true" ? departure.hasFaultyVehicle : false) ||
                          (this.hasNegativeHealthInspection === "true" ? departure.hasNegativeHealthInspection : false) ||
                          (this.notDeparted ? departure.hasNotDeparted : false);
            })
            .filter((departure) =>
                this.vehicleTypes.length
                    ? this.vehicleTypes.includes(
                          departure.servingDriverAndVehicle.vehicle == null || departure.servingDriverAndVehicle.vehicle?.type == null
                              ? DeparturesListFilters.NO_VEHICLE
                              : departure.servingDriverAndVehicle.vehicle.type.label()
                      )
                    : true
            )
            .filter((departure) => (this.stopNames.length ? this.stopNames.includes(departure.stop?.name ?? "") : true))
            .filter((departure) => (this.driverDepartment.length ? this.driverDepartment.includes(departure.servingDriverAndVehicle.driver.department ?? "") : true))
            .filter((departure) => (this.driverGroups.length ? this.driverGroups.includes(departure.servingDriverAndVehicle.driver.group ?? "") : true))
            .filter((departure) =>
                this.searchString?.length != null && this.searchString.length >= 1
                    ? departure.servingDriverAndVehicle.driver.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
                      departure.servingDriverAndVehicle.vehicle?.garageNumber?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                      departure.servingDriverAndVehicle.driver.tableNumber.toLowerCase().includes(this.searchString.toLowerCase())
                    : true
            );
    }

    filterByPlannedDeparture(departures: api.DepartureViewModel[]): api.DepartureViewModel[] {
        const filterFrom = this.plannedDepartureFrom != null && moment(this.plannedDepartureFrom, "HH:mm").isValid() ? moment(this.plannedDepartureFrom, "HH:mm") : undefined;
        let filterTo = this.plannedDepartureTo != null && moment(this.plannedDepartureTo, "HH:mm").isValid() ? moment(this.plannedDepartureTo, "HH:mm") : undefined;

        if (filterFrom != null && filterTo != null && filterTo < filterFrom) {
            filterTo = filterTo.add(1, "day"); // tomorrow night
        }

        return departures
            .filter((departure) => (filterFrom != null ? moment(departure.plannedDeparture) >= filterFrom : true))
            .filter((job) => (filterTo != null ? moment(job.plannedDeparture) <= filterTo : true));
    }
}
