import { reactive } from "vue";
import { OnUpdateTimeChanged, UpdateTimesHub } from "@/features/externalServicesMonitoring/updateTimes/UpdateTimesHub";
import { IHub } from "@/components/signalR/HubConnectionProvider";
import { JobDelaysHub, OnJobDelayUpsert } from "@/features/jobs/JobDelaysHub";
import { DriverArrivalToJobsHub, OnDriverArrivedToJob, OnDriverJobPlannedArrivalRemovedMessage } from "@/features/preparation/departures/signalR/DriverArrivalToJobsHub";
import { OnInProgressNewMonitoredJobAdded, OnVehicleDeparted, OnVehicleDepartureRemoved, VehicleDeparturesHub } from "@/features/shared/signalR/VehicleDeparturesHub";
import { OnEndedJobAdded, OnVehicleArrivalRemoved, OnVehicleArrived, VehicleArrivalsHub } from "@/features/shared/signalR/VehicleArrivalsHub";
import { ServiceAccountViewHub, ServiceAccountViewUpdateMessage } from "@/features/shared/signalR/ServiceAccountsHub";
import { AllVehicleLocationSnapshotsHub, OnAllVehicleLocationSnapshotsUpdate } from "@/features/shared/signalR/AllVehicleLocationSnapshotsHub";
import { OnSingleVehicleLocationSnapshotUpdate, SingleVehicleLocationSnapshotHub } from "@/features/shared/signalR/SingleVehicleLocationSnapshotHub";
import { HealthInspectionHub, OnHealthInspectionRemove, OnHealthInspectionUpsert } from "@/features/shared/signalR/HealthInspectionHub";
import { IncidentHub, OnIncidentDelete, OnIncidentUpsert } from "@/features/shared/signalR/IncidentHub";
import {
    OnPubliclyAccessibleVehicleNotification,
    OnPubliclyInaccessibleVehicleNotification,
    VehiclePublicAccessibilityChangedHub,
} from "@/features/shared/signalR/VehiclePublicAccessibilityChangedHub";
import { DeparturesHub, OnDeparturesDeleted, OnDeparturesUpserted } from "@/features/preparation/departures/signalR/DeparturesHub";
import { ArrivalsToTerminalHub, OnNotifyOfDriverAndVehicleChangeNotification } from "@/features/terminals/viru/arrival/ArrivalsToTerminalHub";
import { DeparturesToTerminalHub } from "@/features/terminals/viru/departure/DeparturesToTerminalHub";
import { OnVehicleBatteryChargesMessage, VehicleBatteryChargesHub } from "@/features/vehicles/VehicleBatteryChargesHub";
import {
    GlobalNotificationsHub,
    OnExpiredUpdateTimesHubMessage,
    OnExpiringReroutesHubMessage,
    OnExternalServiceInterruptionsHubMessage,
    OnInactiveAlcometersHubMessage,
    OnPubliclyInaccessibleVehiclesHubMessage,
    OnVehiclesOnLowBatteryHubMessage,
} from "@/features/shared/signalR/GlobalNotificationsHub";

class HubController {
    /// Automatically closeable hubs

    private _automaticallyCloseableHubs: IHub[] = [];

    // Incidents

    private _incidentHubHandler: IncidentHub | undefined;

    public async startIncidentHub(onUpsert: OnIncidentUpsert, onDelete: OnIncidentDelete): Promise<IncidentHub> {
        if (this._incidentHubHandler == null) {
            this._incidentHubHandler = new IncidentHub();
        }
        const hub = await this._incidentHubHandler.open(onUpsert, onDelete);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Health inspections

    private _healthInspectionHubHandler: HealthInspectionHub | undefined;

    public async startHealthInspectionHub(onUpsert: OnHealthInspectionUpsert, onRemove: OnHealthInspectionRemove): Promise<HealthInspectionHub> {
        if (this._healthInspectionHubHandler == null) {
            this._healthInspectionHubHandler = new HealthInspectionHub();
        }
        const hub = await this._healthInspectionHubHandler.open(onUpsert, onRemove);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Vehicle location snapshots

    private _vehicleLocationSnapshotHubHandler: SingleVehicleLocationSnapshotHub | undefined;
    private _vehicleLocationSnapshotsHubHandler: AllVehicleLocationSnapshotsHub | undefined;

    public async startSingleVehicleLocationSnapshotHubHandler(vehicleId: number, onUpdate: OnSingleVehicleLocationSnapshotUpdate): Promise<SingleVehicleLocationSnapshotHub> {
        if (this._vehicleLocationSnapshotHubHandler == null) {
            this._vehicleLocationSnapshotHubHandler = new SingleVehicleLocationSnapshotHub(vehicleId);
        }
        const hub = await this._vehicleLocationSnapshotHubHandler.open(onUpdate);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    public async startAllVehicleLocationSnapshotsHubHandler(onUpdate: OnAllVehicleLocationSnapshotsUpdate): Promise<AllVehicleLocationSnapshotsHub> {
        if (this._vehicleLocationSnapshotsHubHandler == null) {
            this._vehicleLocationSnapshotsHubHandler = new AllVehicleLocationSnapshotsHub();
        }
        const hub = await this._vehicleLocationSnapshotsHubHandler.open(onUpdate);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Update times

    private _updateTimesHubHandler: UpdateTimesHub | undefined;

    public async startUpdateTimesHub(onChange: OnUpdateTimeChanged): Promise<UpdateTimesHub> {
        if (this._updateTimesHubHandler == null) {
            this._updateTimesHubHandler = new UpdateTimesHub();
        }
        const hub = await this._updateTimesHubHandler.open(onChange);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Departures

    private _departuresHubHandler: DeparturesHub | undefined;

    public async startDeparturesHub(onDepartureUpserted: OnDeparturesUpserted, onDepartureDeleted: OnDeparturesDeleted): Promise<DeparturesHub> {
        if (this._departuresHubHandler == null) {
            this._departuresHubHandler = new DeparturesHub();
        }
        const hub = await this._departuresHubHandler.open(onDepartureUpserted, onDepartureDeleted);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Driver arrivals

    private _driverArrivalToJobsHub: DriverArrivalToJobsHub | undefined;

    public async startDriverArrivalToJobsHub(onDriverArrivedToJob: OnDriverArrivedToJob, onDriverJobPlannedArrivalRemoved: OnDriverJobPlannedArrivalRemovedMessage): Promise<DriverArrivalToJobsHub> {
        if (this._driverArrivalToJobsHub == null) {
            this._driverArrivalToJobsHub = new DriverArrivalToJobsHub();
        }
        const hub = await this._driverArrivalToJobsHub.open(onDriverArrivedToJob, onDriverJobPlannedArrivalRemoved);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Vehicle departures

    private _vehicleDeparturesHubHandler: VehicleDeparturesHub | undefined;

    public async startVehicleDeparturesHub(onChange: OnVehicleDeparted, onChangeRemoved: OnVehicleDepartureRemoved): Promise<VehicleDeparturesHub> {
        if (this._vehicleDeparturesHubHandler == null) {
            this._vehicleDeparturesHubHandler = new VehicleDeparturesHub();
        }
        const hub = await this._vehicleDeparturesHubHandler.openVehicleDeparted(onChange, onChangeRemoved);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Vehicle arrivals

    private _vehicleArrivalsHubHandler: VehicleArrivalsHub | undefined;

    public async startVehicleArrivalsHub(onChange: OnVehicleArrived, onChangeRemoved: OnVehicleArrivalRemoved): Promise<VehicleArrivalsHub> {
        if (this._vehicleArrivalsHubHandler == null) {
            this._vehicleArrivalsHubHandler = new VehicleArrivalsHub();
        }
        const hub = await this._vehicleArrivalsHubHandler.openVehicleArrived(onChange, onChangeRemoved);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // In progress monitored jobs

    private _inProgressMonitoredJobHubHandler: VehicleDeparturesHub | undefined;

    public async startOnNewMonitoredJobAdded(onChange: OnInProgressNewMonitoredJobAdded): Promise<VehicleDeparturesHub> {
        if (this._inProgressMonitoredJobHubHandler == null) {
            this._inProgressMonitoredJobHubHandler = new VehicleDeparturesHub();
        }
        const hub = await this._inProgressMonitoredJobHubHandler.openInProgressNewMonitoredJobAdded(onChange);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Ended jobs monitoring

    private _endedMonitoredJobsHubHandler: VehicleArrivalsHub | undefined;

    public async startOnEndedJobAdded(onChange: OnEndedJobAdded): Promise<VehicleArrivalsHub> {
        if (this._endedMonitoredJobsHubHandler == null) {
            this._endedMonitoredJobsHubHandler = new VehicleArrivalsHub();
        }
        const hub = await this._endedMonitoredJobsHubHandler.openEndedJobAdded(onChange);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Job delays

    private _jobDelaysHubHandler: JobDelaysHub | undefined;

    public async startJobDelaysHub(onUpsert: OnJobDelayUpsert): Promise<JobDelaysHub> {
        if (this._jobDelaysHubHandler == null) {
            this._jobDelaysHubHandler = new JobDelaysHub();
        }
        const hub = await this._jobDelaysHubHandler.open(onUpsert);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Vehicle made publicly accessible
    private _vehiclePublicAccessibilityChangedHubHandler: VehiclePublicAccessibilityChangedHub | undefined;

    public async startVehiclePublicAccessibilityChangedHub(
        onNotifyOfAccessibleVehicle: OnPubliclyAccessibleVehicleNotification,
        onNotifyOfInaccessibleVehicle: OnPubliclyInaccessibleVehicleNotification
    ): Promise<VehiclePublicAccessibilityChangedHub> {
        if (this._vehiclePublicAccessibilityChangedHubHandler == null) {
            this._vehiclePublicAccessibilityChangedHubHandler = new VehiclePublicAccessibilityChangedHub();
        }
        const hub = await this._vehiclePublicAccessibilityChangedHubHandler.open(onNotifyOfAccessibleVehicle, onNotifyOfInaccessibleVehicle);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    // Vehicle battery charges
    private _vehicleBatteryChargesHubHandler: VehicleBatteryChargesHub | undefined;

    public async startVehicleBatteryChargesHub(onMessage: OnVehicleBatteryChargesMessage): Promise<VehicleBatteryChargesHub> {
        if (this._vehicleBatteryChargesHubHandler == null) {
            this._vehicleBatteryChargesHubHandler = new VehicleBatteryChargesHub();
        }
        const hub = await this._vehicleBatteryChargesHubHandler.open(onMessage);
        this._automaticallyCloseableHubs.push(hub);
        return hub;
    }

    /// Global hubs -  Should be started in main.ts and after login. Should be closed after logout

    async closeAll() {
        while (this._automaticallyCloseableHubs.length > 0) {
            await this._automaticallyCloseableHubs.pop()?.close();
        }
    }

    // Global navbar notifications:

    private _globalNotificationsHub: GlobalNotificationsHub | undefined;

    public async startGlobalNotificationsHub(
        onVehiclesOnLowBatteryMessage: OnVehiclesOnLowBatteryHubMessage,
        onPubliclyInaccessibleVehiclesMessage: OnPubliclyInaccessibleVehiclesHubMessage,
        onExpiredUpdateTimesMessage: OnExpiredUpdateTimesHubMessage,
        onExternalServiceInterruptionsMessage: OnExternalServiceInterruptionsHubMessage,
        onExpiringReroutesMessage: OnExpiringReroutesHubMessage,
        onInactiveAlcometersMessage: OnInactiveAlcometersHubMessage
    ): Promise<GlobalNotificationsHub> {
        if (this._globalNotificationsHub == null) {
            this._globalNotificationsHub = new GlobalNotificationsHub();
        }
        const hub = await this._globalNotificationsHub.open(
            onVehiclesOnLowBatteryMessage,
            onPubliclyInaccessibleVehiclesMessage,
            onExpiredUpdateTimesMessage,
            onExternalServiceInterruptionsMessage,
            onExpiringReroutesMessage,
            onInactiveAlcometersMessage
        );
        this._automaticallyCloseableHubs.push(hub);
        await hub.getCurrentState();
        return hub;
    }

    // Service accounts

    private _serviceAccountViewHub: ServiceAccountViewHub | undefined;

    public async startServiceAccountViewHub(userId: number, onUpdate: (message: ServiceAccountViewUpdateMessage) => void, onApplicationRestart: () => void): Promise<ServiceAccountViewHub> {
        if (this._serviceAccountViewHub == null) {
            this._serviceAccountViewHub = new ServiceAccountViewHub();
        }
        return await this._serviceAccountViewHub.open(onUpdate, onApplicationRestart);
    }

    // Arrivals to terminal hub

    private _arrivalsToTerminalHub: ArrivalsToTerminalHub | undefined;

    public async startArrivalsToTerminalHub(onNotifyOfDriverAndVehicleChange: OnNotifyOfDriverAndVehicleChangeNotification): Promise<ArrivalsToTerminalHub> {
        if (this._arrivalsToTerminalHub == null) {
            this._arrivalsToTerminalHub = new ArrivalsToTerminalHub();
        }
        const hub = await this._arrivalsToTerminalHub.open(onNotifyOfDriverAndVehicleChange);
        this._automaticallyCloseableHubs.push(hub);

        await hub.getCurrentState();
        return hub;
    }

    // Departures to terminal hub

    private _departuresToTerminalHub: DeparturesToTerminalHub | undefined;

    public async startDeparturesToTerminalHub(onNotifyOfDriverAndVehicleChange: OnNotifyOfDriverAndVehicleChangeNotification): Promise<DeparturesToTerminalHub> {
        if (this._departuresToTerminalHub == null) {
            this._departuresToTerminalHub = new DeparturesToTerminalHub();
        }
        const hub = await this._departuresToTerminalHub.open(onNotifyOfDriverAndVehicleChange);
        this._automaticallyCloseableHubs.push(hub);

        await hub.getCurrentState();
        return hub;
    }
}

const hubController = reactive(new HubController());
export default hubController;
