import { api } from "@/api-client";

class RerouteTypeTranslator {
    translateFromRerouteType(type: api.RerouteType) {
        switch (type) {
            case api.RerouteType.Other:
                return "Muu";
            case api.RerouteType.Reroute:
                return "Ümbersõit";
        }
    }

    translateFromString(type: keyof typeof api.RerouteType): string {
        return this.translateFromRerouteType(api.RerouteType[type]);
    }
}

const rerouteTypeTranslator = new RerouteTypeTranslator();
export default rerouteTypeTranslator;
