import hubController from "@/components/signalR/HubController";
import { App, Ref, ref, Plugin, InjectionKey } from "vue";
import userInfo from "@/components/UserInfo";
import { IAppPlugin } from "@/plugins/AppPlugins";
import {
    GlobalNotificationsHub,
    OnExpiredUpdateTimesHubMessage,
    OnExpiringReroutesHubMessage,
    OnExternalServiceInterruptionsHubMessage,
    OnInactiveAlcometersHubMessage,
    OnPubliclyInaccessibleVehiclesHubMessage,
    OnVehiclesOnLowBatteryHubMessage,
    VehiclesOnLowBatteryHubMessage,
} from "@/features/shared/signalR/GlobalNotificationsHub";
import { api } from "@/api-client";

export class GlobalNavbarNotifier implements IAppPlugin {
    private _globalNotificationsHubHandler: GlobalNotificationsHub | null = null;

    public static readonly INSTANCE_KEY: InjectionKey<GlobalNavbarNotifier> = Symbol("GLOBAL_NAVBAR_NOTIFIER_KEY");

    // Vehicles on low battery
    public static readonly VEHICLES_ON_LOW_BATTERY_MESSAGE_KEY: InjectionKey<Ref<VehiclesOnLowBatteryHubMessage>> = Symbol("GLOBAL_NAVBAR_NOTIFIER_MESSAGE_KEY");

    private _onVehiclesOnLowBatteryHubMessage: OnVehiclesOnLowBatteryHubMessage = (message) => {
        this._vehiclesOnLowBatteryNotification.value = message;
    };

    private _vehiclesOnLowBatteryNotification = ref<VehiclesOnLowBatteryHubMessage>(new VehiclesOnLowBatteryHubMessage());

    // Publicly inaccessible vehicles

    public static readonly PUBLICLY_INACCESSIBLE_VEHICLES_MESSAGE_KEY: InjectionKey<Ref<boolean>> = Symbol("PUBLICLY_INACCESSIBLE_VEHICLES_MESSAGE_KEY");
    private _onPubliclyInaccessibleVehiclesHubMessage: OnPubliclyInaccessibleVehiclesHubMessage = (message) => {
        this._showPubliclyInaccessibleVehiclesNotification.value = message.hasPubliclyInaccessibleVehicles;
    };
    private _showPubliclyInaccessibleVehiclesNotification = ref<boolean>(false);

    // Expired updates

    public static readonly EXPIRED_UPDATES_MESSAGE_KEY: InjectionKey<Ref<api.ExternalServiceOperationName[]>> = Symbol("EXPIRED_UPDATES_MESSAGE_KEY");
    private _onExpiredUpdateTimesHubMessage: OnExpiredUpdateTimesHubMessage = (message) => {
        this._expiredUpdateTimesNotification.value = message.updateNames;
    };
    private _expiredUpdateTimesNotification = ref<api.ExternalServiceOperationName[]>([]);

    // External service interruptions

    public static readonly EXTERNAL_SERVICE_INTERRUPTIONS_MESSAGE_KEY: InjectionKey<Ref<boolean>> = Symbol("EXTERNAL_SERVICE_INTERRUPTIONS_MESSAGE_KEY");
    private _onExternalServiceInterruptionsHubMessage: OnExternalServiceInterruptionsHubMessage = (message) => {
        this._showExternalServiceInterruptionsNotification.value = message.showNotification;
    };
    private _showExternalServiceInterruptionsNotification = ref<boolean>(false);

    // Expiring reroutes

    public static readonly EXPIRING_REROUTES_MESSAGE_KEY: InjectionKey<Ref<boolean>> = Symbol("EXPIRING_REROUTES_MESSAGE_KEY");

    private _onExpiringReroutesHubMessage: OnExpiringReroutesHubMessage = (message) => {
        this._showExpiringReroutesNotification.value = message.hasExpiringReroutes;
    };
    private _showExpiringReroutesNotification = ref<boolean>(false);

    // Inactive alcometers
    public static readonly INACTIVE_ALCOMETERS_MESSAGE_KEY: InjectionKey<Ref<boolean>> = Symbol("INACTIVE_ALCOMETERS_MESSAGE_KEY");

    private _onInactiveAlcometersHubMessage: OnInactiveAlcometersHubMessage = (message) => {
        this._showInactiveAlcometersNotification.value = message.hasInactiveAlcometers;
    };
    private _showInactiveAlcometersNotification = ref<boolean>(false);

    install = (app: App, options: unknown) => {
        if (userInfo.isAuthenticated && userInfo.canConnectToGlobalNotificationsHub) {
            hubController
                .startGlobalNotificationsHub(
                    this._onVehiclesOnLowBatteryHubMessage,
                    this._onPubliclyInaccessibleVehiclesHubMessage,
                    this._onExpiredUpdateTimesHubMessage,
                    this._onExternalServiceInterruptionsHubMessage,
                    this._onExpiringReroutesHubMessage,
                    this._onInactiveAlcometersHubMessage
                )
                .then((handler) => (this._globalNotificationsHubHandler = handler));
        }

        app.provide(GlobalNavbarNotifier.VEHICLES_ON_LOW_BATTERY_MESSAGE_KEY, this._vehiclesOnLowBatteryNotification);
        app.provide(GlobalNavbarNotifier.PUBLICLY_INACCESSIBLE_VEHICLES_MESSAGE_KEY, this._showPubliclyInaccessibleVehiclesNotification);
        app.provide(GlobalNavbarNotifier.EXPIRED_UPDATES_MESSAGE_KEY, this._expiredUpdateTimesNotification);
        app.provide(GlobalNavbarNotifier.EXTERNAL_SERVICE_INTERRUPTIONS_MESSAGE_KEY, this._showExternalServiceInterruptionsNotification);
        app.provide(GlobalNavbarNotifier.EXPIRING_REROUTES_MESSAGE_KEY, this._showExpiringReroutesNotification);
        app.provide(GlobalNavbarNotifier.INACTIVE_ALCOMETERS_MESSAGE_KEY, this._showInactiveAlcometersNotification);
        app.provide(GlobalNavbarNotifier.INSTANCE_KEY, this);
    };

    public reloadIfNeeded = async () => {
        if (this._globalNotificationsHubHandler == null || this._globalNotificationsHubHandler.isClosed()) {
            await hubController.startGlobalNotificationsHub(
                this._onVehiclesOnLowBatteryHubMessage,
                this._onPubliclyInaccessibleVehiclesHubMessage,
                this._onExpiredUpdateTimesHubMessage,
                this._onExternalServiceInterruptionsHubMessage,
                this._onExpiringReroutesHubMessage,
                this._onInactiveAlcometersHubMessage
            );
        }
    };
}

export default new GlobalNavbarNotifier().install as Plugin;
