import { IPagination, ListFiltersWithPaging } from "@/components/ListFilters";
import { api } from "@/api-client";
import { LocationQuery } from "vue-router";
import translator from "@/features/Translator";

export interface IReroutesListFilters extends IPagination {
    searchString: string | undefined;
    transportTypes: string[];
    rerouteTypes: string[];
}

export class ReroutesListFilters extends ListFiltersWithPaging implements IReroutesListFilters {
    searchString: string | undefined = undefined;
    transportTypes: string[] = [];
    rerouteTypes: string[] = [];

    constructor(data?: Partial<IReroutesListFilters>) {
        super(data);
        this.searchString = data?.searchString ?? undefined;
        this.transportTypes = data?.transportTypes ?? [];
        this.rerouteTypes = data?.rerouteTypes ?? [];
    }

    mapFromQuery(query: LocationQuery) {
        super.mapFromQuery(query);
        this.transportTypes = query.soidukiliik ? String(query.soidukiliik).split(",") : [];
        this.rerouteTypes = query.umbersoiduliik ? String(query.umbersoiduliik).split(",") : [];
        this.searchString = query.otsing ? String(query.otsing) : undefined;
    }

    createQuery() {
        return {
            ...super.createQuery(),
            otsing: this.searchString?.length ? this.searchString : undefined,
            soidukiliik: this.transportTypes.length ? this.transportTypes : undefined,
            umbersoiduliik: this.rerouteTypes.length ? this.rerouteTypes : undefined,
        };
    }

    clearAllFilters() {
        return new ReroutesListFilters();
    }

    applyOn(reroutes: api.RerouteListViewModel[]): api.RerouteListViewModel[] {
        return (
            reroutes
                .filter((reroute) => filterByTransportType(this.transportTypes, reroute))
                .filter((reroute) => filterByRerouteType(this.rerouteTypes, reroute))
                // @ts-expect-error
                .filter((reroute) => filterBySearchString(this.searchString, reroute))
        );

        function filterByTransportType(transportTypes: string[], reroute: api.RerouteListViewModel): boolean {
            if (!transportTypes.length) {
                return true;
            }
            return transportTypes.includes(translator.MobisInstance.translateToVehicleType(reroute.transportType));
        }

        function filterByRerouteType(rerouteTypes: string[], reroute: api.RerouteListViewModel): boolean {
            if (!rerouteTypes.length) {
                return true;
            }
            return rerouteTypes.includes(translator.RerouteType.translateFromRerouteType(reroute.rerouteType));
        }

        function filterBySearchString(searchString: string, reroute: api.RerouteListViewModel): boolean {
            if (!searchString) {
                return true;
            }
            return reroute.fileName.toLowerCase().includes(searchString.toLowerCase()) || reroute.lineNumbers.some((ln) => ln.lineNumber.toLowerCase().includes(searchString.toLowerCase()));
        }
    }
}
