import rerouteTypeTranslator from "@/features/RerouteTypeTranslator";
import healthInspectionResultTranslator from "@/features/HealthInspectionResultTranslator";
import incidentStatusFlagTranslator from "@/features/IncidentStatusFlagTranslator";
import mobisInstanceTranslator from "@/features/MobisInstanceTranslator";
import workTypeTranslator from "@/features/WorkTypeTranslator";

class Translator {
    RerouteType = rerouteTypeTranslator;
    HealthInspectionResult = healthInspectionResultTranslator;
    IncidentStatusFlag = incidentStatusFlagTranslator;
    MobisInstance = mobisInstanceTranslator;
    WorkType = workTypeTranslator;
}

const translator = new Translator();
export default translator;
