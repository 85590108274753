import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

export class VehicleBatteryChargesMessage extends HubMessage<VehicleBatteryChargesMessage> {
    models!: api.VehicleBatteryChargeViewModel[];

    public override overrideFromJSON(data: any): VehicleBatteryChargesMessage {
        const result = new VehicleBatteryChargesMessage();
        result.models = data["models"];
        return result;
    }
}

export type OnVehicleBatteryChargesMessage = (message: VehicleBatteryChargesMessage) => void;

export class VehicleBatteryChargesHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("vehicle-battery-charges-hub");
    }

    public async open(onMessage: OnVehicleBatteryChargesMessage) {
        await super.onMessage(VehicleBatteryChargesMessage, "SendBatteryChargesMessage", onMessage);
        await super.start();

        return this;
    }

    public async close() {
        await super.stop();
    }
}
